<template>
  <b-navbar class="app-header" toggleable="lg" type="dark" sticky>  
    <b-badge class="app-header-dev-badge" v-if="isEnvDevelopment && !isMobile" variant="danger">
      DEV
    </b-badge>
    <b-badge class="app-header-dev-badge" v-if="isEnvTest && !isMobile" variant="warning">
      TEST
    </b-badge>

    <b-navbar-brand href="/" v-if="!isMobile && !isOffline">
      <img
        class="app-header-logo float-left"
        src="/images/logos/logo_white.png"
        srcset="/images/logos/logo_white.webp, /images/logos/logo_white.png"
        alt="volateq"
      />
    </b-navbar-brand>

    <img v-if="isMobile || isOffline"
      class="app-header-logo float-left"
      src="/images/logos/logo_white.png"
      srcset="/images/logos/logo_white.webp, /images/logos/logo_white.png"
      alt="volateq"
    />

    <b-navbar-toggle target="nav-collapse" v-show="!isOffline" />
    <b-icon-cloud-slash v-show="isOffline" />

    <b-collapse class="app-header-menu" id="nav-collapse" is-nav>
      <b-navbar-nav v-show="!isOffline">
        <b-nav-item v-if="hasPermPlantView" class="link" href="/plants" :active="isActiveRoute(['/plants', '/plant/*'])">
          <span class="nav-item-text">{{ $t("plants") }}</span>
        </b-nav-item>

        <!-- TODO: check for permission READ_ANALYSIS -->
        <b-nav-item v-if="hasPerm(p.READ_ANALYSIS)" class="link" href="/analyses" :active="isActiveRoute(['/analyses', '/analysis/new'])">
          <span class="nav-item-text">{{ $t("analyses") }}</span>
        </b-nav-item>

        <b-nav-item v-if="hasPerm(p.LIST_DOC_FILE, p.DOWNLOAD_DOC_FILE)" class="link" href="/doc/files" :active="isActiveRoute(['/doc/files'])">
          <span class="nav-item-text">{{ $t("documentation") }}</span>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto" v-if="updateAvailable && !isOffline">
        <b-nav-item-dropdown toggle-class="app-header-nav-update-dropdown" right ref="updateDropdown">
          <template #button-content>
            <b-icon icon="cloud-upload-fill" font-scale="1.5" />
          </template>

         <b-container class="app-header-nav-update-container">
            <span>{{ $t("new-version-available") }}</span>
            <b-button @click="reload()">{{ $t("update-now") }} ({{ updateRemainingSeconds }})</b-button>
         </b-container>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav v-show="!isOffline" :class="!updateAvailable && 'ml-auto'">
        <b-nav-item-dropdown toggle-class="app-header-nav-dropdown" right>
          <template #button-content>
            <!-- Only for christmas (xmas) time -->
            <!-- <img src="/images/x-mas-head.png" class="xmas-head" v-if="!isMobile" /> -->
            <b-icon icon="gear-fill" font-scale="1.5" />
          </template>

          <b-dropdown-item href="/settings/users" v-if="hasAdminPerm(p.READ_USER)" class="link">{{ $t("users") }} <app-super-admin-marker /></b-dropdown-item>
          <b-dropdown-item href="/settings/customers" v-if="hasAdminPerm(p.READ_CUSTOMER)" class="link">{{ $t("customers") }} <app-super-admin-marker /></b-dropdown-item>
          <b-dropdown-item href="/settings/product-packages" v-if="hasAdminPerm(p.READ_PRODUCT_PACKAGE)" class="link">{{ $t("product-packages") }} <app-super-admin-marker /></b-dropdown-item>
          <b-dropdown-item href="/settings/orders" v-if="hasAdminPerm(p.READ_ORDER)" class="link">{{ $t("orders") }} <app-super-admin-marker /></b-dropdown-item>
          <b-dropdown-item href="/settings/drones" v-if="hasAdminPerm(p.READ_DRONE)" class="link">{{ $t("drones") }} <app-super-admin-marker /></b-dropdown-item>
          <b-dropdown-item href="/settings/monitoring" v-if="hasAdminPerm(p.READ_TASK, p.READ_QFLY_SERVER)" class="link">{{ $t("monitoring") }} <app-super-admin-marker /></b-dropdown-item>

          <b-dropdown-divider v-if="isAdmin" />

          <b-dropdown-form v-if="hasPerm(p.SWITCH_CUSTOMER)">
            <div class=""><small class="text-grey" v-if="!!selectedCustomer">{{ selectedCustomer.name }}</small></div>
            <app-button variant="secondary" cls="width-100pc" @click="onShowSwitchCustomerModal" :title="$t('switch-customer')"> {{ $t("switch") }}<app-super-admin-marker /></app-button>
          </b-dropdown-form>

          <b-dropdown-divider v-if="hasPerm(p.SWITCH_CUSTOMER)" />

          <b-dropdown-item href="/settings/user-profile" class="link">
            {{ $t("user-profile") }} <span clas="pad-left-half"><app-icon icon="person-fill" /></span>
          </b-dropdown-item>

          <b-dropdown-item v-if="hasPerm(p.READ_RELEASE_NOTES)" class="link" @click="onShowReleaseNotes">
            {{ $t("info") }} <span clas="pad-left-half"><b-icon icon="info-circle-fill" scale="0.9" /></span>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-form>
            <b-button @click="logout" class="width-100pc">{{ $t("logout") }}</b-button>
          </b-dropdown-form>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

    <app-modal-form v-if="hasPerm(p.SWITCH_CUSTOMER)"
      id="switch-customer-modal"
      ref="switchCustomerModal"
      :title="$t('switch')"
      :subtitle="$t('switch-customer-and-change-PIs-view')"
      :superAdminProtected="true"
      :modalLoading="switchCustomerLoading"
      :okTitle="$t('apply')"
      :cancelTitle="$t('cancel')"
      @cancel="onSwitchCustomerModalCancel"
      @submit="onCustomerSwitchSubmit"
    >
      <b-alert variant="info" :show="!selectedCustomerId"><span v-html="$t('info-no-customer-selected')"></span></b-alert>
      <b-form-group :label="$t('select-target-customer')">
        <b-form-select v-model="selectedCustomerId" :options="customerSelection" />
      </b-form-group>
      <b-form-group :label="$t('change-pi-view-availability')">
        <b-form-checkbox v-model="showAllKeyFigures">
          {{ $t('show-all-pis') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group :label="$t('hide-super-admin')">
        <b-form-checkbox v-model="hideSuperAdmin">
          {{ $t('hide-super-admin') }}
        </b-form-checkbox>
      </b-form-group>
    </app-modal-form>
    <app-release-notes :showReleaseNotes="showReleaseNotes" v-if="!isOffline" />
  </b-navbar>
</template>

<script lang="ts">
import { Component, Prop, Ref } from "vue-property-decorator";
import { BaseAuthComponent } from "@/app/shared/components/base-auth-component/base-auth-component";
import AppSuperAdminMarker from "@/app/shared/components/app-super-admin-marker/app-super-admin-marker.vue";
import AppButton from "@/app/shared/components/app-button/app-button.vue";
import AppModalForm from "@/app/shared/components/app-modal/app-modal-form.vue";
import AppIcon from "@/app/shared/components/app-icon/app-icon.vue";
import { IAppModalForm } from "../app-modal/types";
import { sortAlphabetical } from "../../services/helper/sort-helper";
import { environment } from "@/environment/environment";
import { CatchError } from '@/app/shared/services/helper/catch-helper';
import { volateqApi } from "../../services/volateq-api/volateq-api";
import AppReleaseNotes from "@/app/release-notes/release-notes.vue";
import { version as buildVersion } from "../../../../../package.json";
import { versionToNumber } from "@/app/shared/services/helper/version-helper";

@Component({
  name: "app-header",
  components: {
    AppSuperAdminMarker,
    AppButton,
    AppModalForm,
    AppIcon,
    AppReleaseNotes,
  }
})
export default class AppHeader extends BaseAuthComponent {
  @Prop({ default: true }) fluid!: boolean;
  @Ref() switchCustomerModal!: IAppModalForm;
  @Ref() updateDropdown!: { show(): void, hide(): void, hideHandler(): void };
  showReleaseNotes = false;

  switchCustomerLoading = false;
  customerSelection: { text: string; value: string | null }[] = [];
  selectedCustomerId: string | null = null;

  showAllKeyFigures = false;
  hideSuperAdmin = false;
  updateAvailable = false;
  updateRemainingSeconds?: number;
  currentVersion = versionToNumber(buildVersion);

  async created() {
    this.hideSuperAdmin = this.isHiddenSuperAdmin;  

    if (!this.isEnvDevelopment) {
      // Run this in background without blocking created()
      setInterval(async () => {
        if (!this.isOffline && !this.updateAvailable) {
          const version = await this.fetchVersion();
          if (version != undefined && version > this.currentVersion) {
            await this.showUpdate();
          }
        }
      }, 600000); // Check every 10 minutes
    }
  }

  @CatchError()
  private async fetchVersion(): Promise<number | void> {
    const request: { version: string } = await fetch("/version.json").then(resp => resp.json());
    return versionToNumber(request.version);
  }

  private async showUpdate() {
    this.updateAvailable = true;
    this.updateRemainingSeconds = 60;
    await this.$nextTick();
    this.updateDropdown?.show();
    this.updateDropdown!.hide = () => {}; // Disallow hiding
    setInterval(() => {
      if (this.updateRemainingSeconds!-- == 0) {
        this.reload();
      } else {
        this.$forceUpdate() // vue reactivity is dum dum
      }
    }, 1000);
  }

  reload() {
    // Some browsers (ie. Firefox) also allow passing a bool as an argument to reload without caches
    (<(boolean) => void>window.location.reload)(true);
  }

  @CatchError()
  async onShowReleaseNotes() {
    this.showReleaseNotes = true;
    await this.$nextTick(); // wait for dom
    this.showReleaseNotes = false; // let user show release notes again after closing them
  }

  async logout(): Promise<void> {
    try {
      await volateqApi.logout();

      this.$router.push({ name: "Login" });
      this.$router.go(0); // Reload the page to refresh the js cache
    } catch (e) {
      console.error(e);
    }
  }

  isActiveRoute(routes: string[]): boolean {
    return !!routes.find(route =>
      route.indexOf("*") != -1
        ? this.$router.currentRoute.path.replace(route.replace("*", ""), "").indexOf("/") == -1
        : this.$router.currentRoute.path === route
    );
  }

  get isEnvDevelopment(): boolean {
    return environment === "development";
  }

  get isEnvTest(): boolean {
    return environment === "staging";
  }
  
  get isEnvProduction(): boolean {
    return environment === "production";
  }

  get isMobile(): boolean {
    return this.store.mobile.isMobile;
  }

  public get isOffline(): boolean {
    return this.store.offline.isOffline;
  }

  async onShowSwitchCustomerModal() {
    this.customerSelection = [
      { value: null, text: "" },
      ...(sortAlphabetical(await volateqApi.getCustomers(), 'name')).map(customer => ({
        value: customer.id,
        text: customer.name,
      })),
    ];

    this.selectedCustomerId = this.selectedCustomer?.id || null;

    const user = await volateqApi.getMe();
    this.showAllKeyFigures = user.profile?.show_all_key_figures || false;

    this.switchCustomerModal.show();
  }

  @CatchError()
  async onSwitchCustomerModalCancel() {
    this.switchCustomerModal.hide();
  }

  async onCustomerSwitchSubmit() {
    this.switchCustomerLoading = true;

    try {
      await volateqApi.switchCustomer(this.selectedCustomerId || undefined, this.showAllKeyFigures);

      let selectedCustomer: any  = this.customerSelection.find(customer => customer.value === this.selectedCustomerId);
      if (selectedCustomer) {
        selectedCustomer = { id: this.selectedCustomerId, name: selectedCustomer.text };
      }

      this.store.auth.updateCustomer(selectedCustomer);
      this.store.auth.updateHidden(this.hideSuperAdmin);

      this.$router.go(0);
    } catch (e) {
      this.showError(e);
    } finally {
      this.switchCustomerLoading = false;
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/_colors.scss";
@import "@/scss/_variables.scss";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

%padtopbot-0 {
  padding-top: 0;
  padding-bottom: 0;
}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0px var(--header-height);
  }
}

.app-release-notes {
  overflow-y: auto;
}

.app-header {
  position: relative;
  height: var(--header-height);
  line-height: var(--header-height);
  background-color: $blue;
  color: $white;

  @extend %padtopbot-0;

  &-dev-badge {
    position: absolute;
    top: 10px;
    left: 142px;
  }

  .navbar-toggler {
    border: none;
    padding-left: 0;
    padding-right: 0;

    &:hover, &:active, &:focus {
      border: none;
      outline: none;
    }
  }

  .xmas-head {
    position: absolute;
    width: 35px;
    top: 15px;
    right: -5px;
  }

  .navbar-toggler {
    border-radius: 0;
  }

  @include media-breakpoint-down(sm) {
    .navbar-collapse {
      margin-right: -1rem;
      margin-left: -1rem;
    }
  }

  &-menu {
    .dropdown-item {
      color: $blue;
    }

    .nav-link {
      @media (max-width: 991px) {
        background: $blue;
      }

      &:not(.dropdown .nav-link) {
        $transition: 0.2s ease-in-out;
        padding: 0 30px !important;
        color: $white !important;
        transition: background $transition, color $transition;

        &:hover {
          background-color: $hover-light-blue !important;
          color: $blue !important;
        }

        &.active {
          background-color: $hover-blue;
        }
      }
    }
  }

  .navbar-brand {
    height: var(--header-height);
    @extend %padtopbot-0;
  }

  &-logo {
    height: var(--header-height);
    margin-left: -15px;
    @extend %padtopbot-0;
  }

  .dropdown {
    &.show > .app-header-nav-dropdown {
      background-color: $white;

      .bi-gear-fill {
        color: $blue;
        transform: rotate(225deg);
      }
    }

    &-menu {
      line-height: 20px;
      margin-top: -1px;

      .dropdown-item {
        text-align: right;
        padding: 0.75rem 1.5rem;
      }

      .b-dropdown-form {
        padding: 0.75rem 1.5rem;
      }
    }

    &-toggle {
      height: var(--header-height);

      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.15s linear;

      &::after {
        content: none;
      }
    }
  }

  .bi-gear-fill, .bi-cloud-upload-fill {
    color: $white;
    transform-origin: center;
    transition: all 0.3s linear;
  }

  &-nav-update-container {
    display: grid !important;
    gap: 0.75rem;
    grid-template-columns: 100%;

    span {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
    }

    button {
      min-width: 20ch;
      width: 100%;
    }
  }
}
</style>
