/**
 * All Perfomance indicators
 */
export enum ApiKeyFigure {
  IR_INTENSITY_ID = 1,
  SCE_ORIENTATION_ID = 2,
  SCA_ORIENTATION_ID = 3,
  SCA_FRICTION_ID = 4,
  MISSING_GLASS_TUBE_ID = 5,
  COATING_DEGRADATION_ID = 6,
  HIGH_HYDROGEN_CONCENTRATION_ID = 7,
  SCA_SDX_ID = 8,
  GLASS_TUBE_TEMPERATURE_ID = 9,
  HCE_RECOMMENDED_ACTION_CLASS_ID = 10,
  MISSING_MIRROR_ID = 11,
  OXYGEN_PENETRATION_ID = 12,
  SCE_SDX_ID = 13,
  SCA_ORTHO_IMAGES_RGB_ONLINE_ID = 14,
  SCA_ORTHO_IMAGES_IR_ID = 15,
  SCA_ORTHO_IMAGES_SDX_ID = 16,
  SCE_ALIGNMENT_ID = 17,
  SCA_TRACKING_DEVIATION_ID = 18,
  HCE_POSITION_ID = 19,
  MODULE_BROKEN_GLASS_ID = 20,
  PID_ID = 21,
  MODULE_OPEN_CIRCUIT_ID = 22,
  MODULE_BYPASS_DIODE_ACTIVE_ID = 23,
  SUBSTRING_OPEN_CIRCUIT_ID = 24,
  CELL_HOTSPOT_ID = 25,
  CELL_TRANSFER_RESISTANCE_ID = 26,
  SCA_ORTHO_IMAGES_RGB_OFFLINE_ID = 27,
  SCA_BALL_JOINT_FRICTION_ID = 28,
  HOT_GLASS_TUBE_ENDS_ID = 29,
  TRACKING_DEVIATION_ID = 30,
  MODULE_SOILING_LEVEL_ID = 31,
  TRACKER_SOILING_LEVEL_ID = 32,
  TRACKER_ORTHO_IMAGES_RGB_ID = 33,
  TRACKER_ORTHO_IMAGES_IR_ID = 34,
  MODULE_ORTHO_IMAGES_RGB_ID = 35,
  MODULE_ORTHO_IMAGES_IR_ID = 36,
  SWIVEL_GRIPPING_POTENTIAL_CLASS_ID = 37,
  SCA_ORTHO_IMAGES_RGB_ONLINE_NO_REFLEX_ID = 38,
  ROTATION_JOINT_GEOMETRY_STATUS_ID = 39,
  WHITE_GLASS_TUBE_ID = 40,
  SHADOWING_ID = 41,
  TRACKER_RAW_IMAGES_IR_ID = 42,
  MODULE_ORTHO_IMAGES_CLEANLINESS_ID = 43,
  TRACKER_ORTHO_IMAGES_CLEANLINESS_ID = 44,
  BJA_HTF_FUMES_ID = 45,
  BJA_ANGLE_ANOMALY_ID = 46,
  BJA_INSULATION_ANOMALY_ID = 47,
  MODULE_ORTHO_IMAGE_FILL_STATUS_ID = 48,
  MULTI_CELL_HOTSPOT_ID = 49,
  HOTSPOT_TEMPERATURE_ID = 50,
  PERFORMANCE_LOSS_ID = 51,
  MODULE_TWO_BYPASS_DIODES_ACTIVE_ID = 52,
  HOT_JUNCTION_BOX_ID = 53,
  MIRROR_DAMAGE_ID = 54,
  MODULE_SOILING_LIMITS = 55,
  MODULE_SOILING_PERFORMANCE_LOSS = 56,
  MODULE_SOILING_OPTICAL_PERFORMANCE_LOSS = 57,
  MODULE_SOILING_HOMOGENEITY = 58,
  TRACKER_SOILING_LIMITS = 59,
  TRACKER_SOILING_PERFORMANCE_LOSS = 60,
  TRACKER_SOILING_OPTICAL_PERFORMANCE_LOSS = 61,
  TRACKER_SOILING_HOMOGENEITY = 62,
  MODULE_SOILING_INDUCED_HOTSPOT = 63,
}
