import { render, staticRenderFns } from "./release-notes-v13.vue?vue&type=template&id=909df00a"
import script from "./release-notes-v13.vue?vue&type=script&lang=ts"
export * from "./release-notes-v13.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jakry/code/volateq-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('909df00a')) {
      api.createRecord('909df00a', component.options)
    } else {
      api.reload('909df00a', component.options)
    }
    module.hot.accept("./release-notes-v13.vue?vue&type=template&id=909df00a", function () {
      api.rerender('909df00a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/release-notes/release-notes-v13.vue"
export default component.exports