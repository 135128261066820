<template>
  <div class="app-loading" :class="{ background: hasLoadingStatus }">
    <div class="app-loading-container">
      <template v-if="hasLoadingStatus">
        <div class="app-loading-container-item" v-for="(loadingState, i) in loadingStates" :key="i">
          <app-spinner />
          <span v-if="loadingState" class="pad-left font-bold app-loading-container-item-loading-state">
            {{ loadingState }}
          </span>
        </div>
      </template>
      <template v-else>
        <app-spinner />
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AppSpinner from "../app-spinner/app-spinner.vue";

@Component({
  name: "app-loading",
  components: {
    AppSpinner,
  }
})
export default class AppLoading extends Vue {
  @Prop({ default: () => [] }) loadingStates!: string[];

  get hasLoadingStatus(): boolean {
    return this.loadingStates.length > 0;
  }
}
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.app-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  &.background {
    background-color: rgba(255,255,255,0.3);
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .app-spinner {
      color: $blue;
    }

    &-item {
      width: 300px;
      margin: 0.5em auto;

      &-loading-state {
        vertical-align: super;
      }
    }
  }
}
</style>
