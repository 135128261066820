<template>
  <div>
    <strong>October 2024 (v8.12.0)</strong><br>
    <hr>
    <a href="#feature-1" v-if="isAdmin">Flight campaign advanced options</a>
    <a href="#feature-2">Linking to components (in Report)</a>
    <hr>
    <div>
      <div v-if="isAdmin">
        <h3 id="feature-1">Flight campaign advanced options</h3>
        <p>
          For us admins there are now advanced options when creating flight campaigns. For example: Specific flight altitude or selecting half or full SCA.
        </p>
      </div>
      <div>
        <h3 id="feature-2">Linking to components (in Report)</h3>
        <p>
          In the details popup next to the title of the selected component in the plant view there is a link icon now.
          Click the link icon to copy the link to the selected component.<br>
          <br>
          PV Reports now have a QR-Code with a link to the PV Tracker.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseReleaseNotesComponent from "./base-release-notes-component"

@Component({
  name: "app-release-notes-v13",
})
export default class AppReleaseNotesV13 extends BaseReleaseNotesComponent {
  releaseNotesVersion = "v13"

  async mounted() {
  }
}
</script>
