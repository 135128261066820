import { volateqApi } from "../volateq-api";
import { ApiComponent } from "../api-components/api-components";
import { ApiKeyFigure } from "../api-key-figures";
import { PlantSchema } from "../api-schemas/plant-schema";
import { GeoVisualCspPtcQuery, GeoVisualPvQuery } from "../api-requests/geo-visual-query-requests";
import { ObservFilterValue, ObservationRequest, SummerizedObservationRequest } from "../api-requests/observation-requests";
import { AnalysisForViewSchema, SimpleAnalysisSchema } from "../api-schemas/analysis-schema";
import { EnabledPiFieldSchema } from "../api-schemas/enabled-pi-field-schema";
import { CustomComponentPropertySchema } from "../api-schemas/custom-component-property-schema";
import { CreatedObservationSchema, ObservationColumn, ObservationColumnValue, ObservationSchema, SummerizedDates, SummerizedObservations } from "../api-schemas/observation-schema";
import { TableFilterRequest, TableRequest } from "../api-requests/common/table-requests";
import { TableResultSchema } from "../api-schemas/table-result-schema";
import { UserSchema } from "../api-schemas/user-schemas";
import { OfflineApiBase } from "./offline-api-base";
import { DBOfflineStores } from "./offline-stores";
import { ConvertedObservation, IVolateqOfflineApi, ObservationGeoJson } from "./types";
import Feature, { FeatureLike } from "ol/Feature";
import { FeatureProperties } from "@/app/plant/shared/map-view/types";
import { CcpService } from "@/app/plant/shared/plant-settings/ccp-service";
import { userService } from "../../user-service/user-service";
import { DateHelper } from "../../helper/date-helper";
import { Geometry } from "ol/geom";
import GeoJSON from "ol/format/GeoJSON";
import { DBOfflineStore } from "./offline-store";
import { GEO_JSON_OPTIONS } from "@/app/shared/components/app-map/constants";
import { hash } from "../../helper/string-helper";
import { store } from "@/app/shared/stores/store";
import { AxiosProgressEvent } from "axios";
import { PerformanceIndicatorSchema } from "../api-schemas/performance-indicator";
import { ApiPI } from "../api-performance-indicator";
import { ApiTechnology } from "../api-technologies";


export class VolateqOfflineApi extends OfflineApiBase implements IVolateqOfflineApi {
  public static offlineApis: Record<string, VolateqOfflineApi> = {};
  public static getCommon(): VolateqOfflineApi {
    if (store.offline.isOffline) {
      return this.offlineApis[store.offline.plant!.id];
    }

    const offlineApiId = "0"; // 0 = No plant. Offline stores are available for all OfflineApis
    if (!(offlineApiId in this.offlineApis)) {
      this.offlineApis[offlineApiId] = new VolateqOfflineApi(null);
    }

    return this.offlineApis[offlineApiId];
  }
  public static get(plant: PlantSchema): VolateqOfflineApi {
    if (!(plant.id in this.offlineApis)) {
      this.offlineApis[plant.id] = new VolateqOfflineApi(plant);
    }

    return this.offlineApis[plant.id];
  }

  

  public addMapTileImage(tileCoordId: string, img: string) {
    const offlineDataStore = this.addAndGetOfflineDataStore(DBOfflineStores.MAP_TILES);
    if (offlineDataStore.data === undefined) {
      offlineDataStore.data = {};
    }

    offlineDataStore.data[tileCoordId] = img;
  }

  public async getOfflineTileMapImages(): Promise<Record<string, string>> {
    if (!this.offlineDB) {
      return {};
    }

    return this.offlineDB?.getAll(DBOfflineStores.MAP_TILES) || {};
  }

  public getAnalysesForView(plantId: string): Promise<AnalysisForViewSchema[]> {
    return this.get(plantId, DBOfflineStores.ANALYSES_FOR_VIEW, () => volateqApi.getAnalysesForView(plantId));
  }

  public getComponentsGeoVisual(
    plantId: string,
    componentIds: ApiComponent[],
    extent?: { min_long: number; max_long: number; min_lat: number; max_lat: number; } | undefined
  ): Promise<any> {
    return this.get(plantId, DBOfflineStores.GEO_VISUAL_COMPONENTS(componentIds[0]), () => {
      return volateqApi.getComponentsGeoVisual(plantId, componentIds, extent);
    });
  }

  public async getComponentsGeoVisualCsv(plantId: string, componentIds: ApiComponent[]): Promise<string> {
    return this.get(plantId, DBOfflineStores.GEO_VISUAL_COMPONENTS_CSV(componentIds[0]), () => {
      return volateqApi.getComponentsGeoVisualCsv(plantId, componentIds);
    });
  }

  public getKeyFiguresGeoVisual(
    plantId: string,
    analysisResultId: string,
    keyFiguresId: ApiKeyFigure,
    query_params?: GeoVisualCspPtcQuery | GeoVisualPvQuery | undefined
  ): Promise<any> {
    return this.get(
      plantId,
      DBOfflineStores.GEO_VISUAL_KEY_FIGURES(analysisResultId, keyFiguresId, query_params),
      () => volateqApi.getKeyFiguresGeoVisual(plantId, analysisResultId, keyFiguresId, query_params),
    );
  }

  private async getObservationsGeoVisualOffline(
    geoVisualObservStore: DBOfflineStore<any>,
    filterFunc: (f: ObservationGeoJson) => boolean
  ): Promise<any> {
    const geoJson: any = this.offlineDB!.objectStores.includes(geoVisualObservStore.name) ?
      await this.getOfflineData(geoVisualObservStore) :
      { type: "FeatureCollection", features: [] };

    const observOfflineFeatures = (await this.getOfflineData(DBOfflineStores.NEW_OBSERVATIONS_GEO_JSON))
      .filter(filterFunc);
    if (observOfflineFeatures.length > 0) {
      geoJson.features.push(...observOfflineFeatures.map(offF => JSON.parse(offF.geojson)));
    }

    return geoJson;
  }

  public getObservationsGeoVisualCcp(
    plantId: string,
    ccpId: string,
    fromDate: string,
    toDate: string,
    filterValue: ObservFilterValue
  ): Promise<any> {
    const visualObservStore = DBOfflineStores.GEO_VISUAL_OBSERV_CCP(
      ccpId,
      fromDate,
      toDate,
      filterValue
    );

    if (store.offline.isOffline) {
      return this.getObservationsGeoVisualOffline(
        visualObservStore,
        f => !!f.ccps.find(ccp => ccp.ccp_id === ccpId 
          && (filterValue === undefined || ccp.value === filterValue.toString()))
      );
    }

    return this.get(
      plantId,
      visualObservStore,
      () => volateqApi.getObservationsGeoVisualCcp(plantId, ccpId, fromDate, toDate, filterValue),
    );
  }

  public async getObservationsGeoVisualPi(
    plantId: string,
    piId: ApiPI,
    fromDate: string,
    toDate: string,
    filterValue: ObservFilterValue
  ): Promise<any> {
    const visualObservStore = DBOfflineStores.GEO_VISUAL_OBSERV_PI(
      piId,
      fromDate,
      toDate,
      filterValue
    );

    if (store.offline.isOffline) {
      return this.getObservationsGeoVisualOffline(
        visualObservStore,
        f => !!f.pis.find(pi => pi.pi_id === piId && (filterValue === undefined || pi.value === filterValue.toString()))
      );
    }

    return this.get(
      plantId,
      visualObservStore,
      () => volateqApi.getObservationsGeoVisualPi(plantId, piId, fromDate, toDate, filterValue),
    );
  }

  public getEnabledPiFields(plantId: string): Promise<EnabledPiFieldSchema[]> {
    return this.get(
      plantId,
      DBOfflineStores.ENABLED_PI_FIELDS,
      () => volateqApi.getEnabledPiFields(plantId),
    );
  }

  public getCustomComponentProperties(plantId: string): Promise<CustomComponentPropertySchema[]> {
    return this.get(
      plantId,
      DBOfflineStores.CUSTOM_COMPONENT_PROPERTIES,
      () => volateqApi.getCustomComponentProperties(plantId),
    );
  }

  private async getSummerizedNewObservations(): Promise<SummerizedObservations[]> {
    const newObservStoreCompIds = this.offlineDB!.objectStores
      .filter(s => s.startsWith(DBOfflineStores.NEW_OBSERVATIONS_))
      .map(s => s.replace(DBOfflineStores.NEW_OBSERVATIONS_, ""));

    const summerizedObservs: Record<string, SummerizedObservations> = {};
    for (const compId of newObservStoreCompIds) {
      const observColumns = await this.getOfflineData(DBOfflineStores.OBSERVATION_COLUMNS(Number(compId)));
      const observations = await this.getOfflineData(DBOfflineStores.NEW_OBSERVATIONS(Number(compId)));
      
      for (const observation of observations) {
        const date = observation.observed_at && DateHelper.toDate(observation.observed_at) || "null";
        
        let sumObserv = summerizedObservs[date];
        if (!sumObserv) {
          sumObserv = {
            date,
            d_from: DateHelper.toStartOfDay(date),
            d_to: DateHelper.toEndOfDay(date),
            ccps: [],
            pis: [],
            components: [],
            user_names: [],
            count: 0,
          };
          summerizedObservs[date] = sumObserv;
        }

        const columnIds = Object.keys(observation.column_values);
        for (const columnId of columnIds) {
          const observColumn = observColumns.find(c => c.id === columnId)!;
          if (observColumn.ccp_column) {
            const existingSumCcp = sumObserv.ccps.find(ccp => ccp.ccp_id === observColumn.id);
            if (existingSumCcp) {
              existingSumCcp.count += 1;
            } else {
              sumObserv.ccps.push({ ccp_id: observColumn.id, count: 1 });
            }
          } else if (observColumn.pi_column) {
            const existingSumPi = sumObserv.pis
              .find(pi => pi.pi_id === observColumn.pi_column!.pi_id);
            if (existingSumPi) {
              existingSumPi.count += 1;
            } else {
              sumObserv.pis.push({ 
                pi_id: observColumn.pi_column.pi_id,
                count: 1,
              });
            }
          }
        }
        
        const existingComp = sumObserv.components.find(c => c.component_id === observation.fieldgeometry_component.component_id);
        if (existingComp) {
          existingComp.count += 1;
        } else {
          sumObserv.components.push({ component_id: observation.fieldgeometry_component.component_id, count: 1 });
        }

        if (observation.created_by_user) {
          const existingUsr = sumObserv.user_names.find(u => u === observation.created_by_user!.email);
          if (!existingUsr) {
            sumObserv.user_names.push(observation.created_by_user.email);
          }
        }

        sumObserv.count += 1;
      }
    }

    return Object.values(summerizedObservs);
  } 

  public async getSummerizedOberservations(
    plantId: string,
    summerizedObservationRequest: SummerizedObservationRequest
  ): Promise<SummerizedDates> {
    if (store.offline.isOffline) {
      const summerizedObservations = await this.getSummerizedNewObservations();
      
      const summerizedDates = await this.getOfflineData(DBOfflineStores.SUMMERIZED_DATES);
      summerizedDates.observations = [...(summerizedObservations || []), ...(summerizedDates.observations || [])];

      return summerizedDates;
    }

    return this.get(
      plantId,
      DBOfflineStores.SUMMERIZED_DATES,
      () => volateqApi.getSummerizedOberservations(plantId, summerizedObservationRequest),
    );
  }

  public filterSummerziedObservations(date: string | null) {
    const summerizedDatesStore = this.addAndGetOfflineDataStore(DBOfflineStores.SUMMERIZED_DATES);
    if (summerizedDatesStore.data) {
      summerizedDatesStore.data.observations = summerizedDatesStore.data.observations
        .filter(o => o.date === date);
    }
  }

  public getLastAnalysis(plantId: string, lastDate?: string): Promise<SimpleAnalysisSchema> {
    return this.get(
      plantId,
      DBOfflineStores.LAST_ANALYSIS,
      () => volateqApi.getLastAnalysis(plantId, lastDate)
    );
  }

  public getSpecificAnalysisResult<T>(
    analysisResultId: string,
    componentId: number,
    params: TableRequest,
    filterParams?: TableFilterRequest
  ): Promise<TableResultSchema<T>> {
    return this.get<TableResultSchema<T>, T[]>(
      null,
      DBOfflineStores.ANALYSIS_RESULTS(componentId),
      async () => (await volateqApi.getSpecificAnalysisResult<T>(analysisResultId, componentId, params, filterParams)).items,
      params.dt_id,
      (items) => ({ items, total: items.length })
    );
  }

  public async getObservations(
    plantId: string,
    componentId: number,
    dFrom: string,
    dTo: string,
    params: TableRequest,
    filterParams?: TableFilterRequest,
  ): Promise<TableResultSchema<ObservationSchema, ObservationColumn>> {
    const observColumnsStore = DBOfflineStores.OBSERVATION_COLUMNS(componentId);

    if (store.offline.isOffline) {
      const columns = this.asArray(await this.getOfflineData(observColumnsStore)) as ObservationColumn[];

      const observations = await this.getOfflineData(DBOfflineStores.OBSERVATIONS(componentId), params.dt_id, "dt_id");
      const newObservations = await this.getOfflineData(DBOfflineStores.NEW_OBSERVATIONS(componentId), params.dt_id, "dt_id");
      const allObservations = [...observations, ...newObservations];

      return {
        items: allObservations,
        total: allObservations.length,
        columns
      };
    }

    const observations = await volateqApi.getObservations(plantId, componentId, dFrom, dTo, params, filterParams);
    if (!params.dt_id) {
      const observColumnsDataStore = this.addAndGetOfflineDataStore(observColumnsStore);
      if (observations.columns) {
        observColumnsDataStore.data = observations.columns;
      }
    }

    return this.get(
      plantId,
      DBOfflineStores.OBSERVATIONS(componentId),
      async () => observations.items,
      params.dt_id,
      (items) => ({ items, total: items.length, columns: observations.columns }),
      "dt_id",
    );
  }

  private async convertToObservationSchema(
    observation: ObservationRequest,
    featureProps: FeatureProperties,
  ): Promise<ConvertedObservation> {
    const ccps = await CcpService.get(this.plant!).getCcps();

    const observationColumns: ObservationColumn[] = [];
    const columnValues: ObservationColumnValue = {};
    for (const ccpValue of (observation.ccp_values || [])) {
      observationColumns.push({
        id: ccpValue.ccp_id,
        ccp_column: {
          custom_component_property: ccps.find(ccp => ccp.id === ccpValue.ccp_id)!,
        },
      });

      columnValues[ccpValue.ccp_id] = ccpValue.value;
    }
    for (const piValue of (observation.pi_values || [])) {
      observationColumns.push({
        id: piValue.pi_id.toString(),
        pi_column: { pi_id: piValue.pi_id },
      });
      
      columnValues[piValue.pi_id] = piValue.value;
    }

    return {
      observation: {
        id: "new-observation__" + Math.random().toString(),
        plant_id: this.plant!.id,
        fieldgeometry_component: {
          component_id: featureProps.component,
          pcs: featureProps.pcs,
          dt_id: featureProps.id,
          id: "", // this is
          fielgeometry_id: "", // evil! 💩
        },
        observed_at: observation.observed_at!,
        notes: observation.notes,
        external_id: observation.external_id,
        created_by_user: await userService.me(),
        created_at: DateHelper.toDateTime(new Date()),
        column_values: columnValues,
        request: observation,
        analysis: observation.analysis_id ? {
          id: observation.analysis_id,
          flown_at: "", // this is also
          name: "", // evil.. 💩
        } : undefined,
      },
      columns: observationColumns,
    };
  }

  private async updateObservationColumns(columns: ObservationColumn[], componentId: ApiComponent): Promise<void> {
    const observColumnsStore = DBOfflineStores.OBSERVATION_COLUMNS(componentId);
    for (const column of columns) {
      await this.offlineDB!.update(observColumnsStore, column);
    }
  }

  private getObservationGeoJson(convertedObserv: ConvertedObservation, feature: FeatureLike): ObservationGeoJson {
    const ccps: { ccp_id: string, value: ObservFilterValue }[] = [];
    const pis: { pi_id: ApiPI, value: ObservFilterValue }[] = [];
    for (const id in convertedObserv.observation.column_values) {
      const col = convertedObserv.columns.find(c => c.id === id);
      if (col?.ccp_column) {
        ccps.push({ ccp_id: id, value: convertedObserv.observation.column_values[id] });
      } else if (col?.pi_column) {
        pis.push({ 
          pi_id: col.pi_column.pi_id,
          value: convertedObserv.observation.column_values[id],
        });
      }
    }

    const feat = (feature as Feature<Geometry>).clone();
    feat.setProperties({ offline: true });

    return {
      id: convertedObserv.observation.id,
      geojson: (new GeoJSON()).writeFeature(feat, GEO_JSON_OPTIONS),
      ccps,
      pis
    };
  }

  public async createObservation(plantId: string, observation: ObservationRequest, feature: FeatureLike): Promise<CreatedObservationSchema> {
    if (!store.offline.isOffline) {
      return volateqApi.createObservation(plantId, observation, feature);
    }

    if (!this.offlineDB) {
      throw new Error("OfflineDB disappeared");
    }

    const featureProps = feature.getProperties() as FeatureProperties;
    const convertedObserv = await this.convertToObservationSchema(observation, featureProps);

    await this.updateObservationColumns(convertedObserv.columns, featureProps.component);
    await this.offlineDB.add(DBOfflineStores.NEW_OBSERVATIONS(featureProps.component), convertedObserv.observation);
    await this.offlineDB.add(DBOfflineStores.NEW_OBSERVATIONS_GEO_JSON, this.getObservationGeoJson(convertedObserv, feature));

    return { id: convertedObserv.observation.id, component_id: featureProps.component };
  }

  public async updateObservation(plantId: string, observationId: string, observation: ObservationRequest, feature: FeatureLike): Promise<void> {
    if (!store.offline.isOffline) {
      return volateqApi.updateObservation(plantId, observationId, observation, feature);
    }

    if (!this.offlineDB) {
      throw new Error("OfflineDB disappeared");
    }

    const featureProps = feature.getProperties() as FeatureProperties;

    const observations = await this.getOfflineData(DBOfflineStores.NEW_OBSERVATIONS(featureProps.component), observationId);
    if (observations.length === 0) {
      throw new Error("Observation " + observationId + " not found");
    }
    const oldObservation = observations[0];
    
    const convertedObserv = await this.convertToObservationSchema(observation, featureProps);
    convertedObserv.observation.id = observationId;
    convertedObserv.observation = { ...oldObservation, ...convertedObserv.observation };

    await this.updateObservationColumns(convertedObserv.columns, featureProps.component);
    await this.offlineDB.update(DBOfflineStores.NEW_OBSERVATIONS(featureProps.component), convertedObserv.observation);
    await this.offlineDB.update(DBOfflineStores.NEW_OBSERVATIONS_GEO_JSON, this.getObservationGeoJson(convertedObserv, feature));
  }

  public async uploadObservationImages(
    plantId: string,
    observationId: string,
    images: File[],
    component: ApiComponent,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ) {
    if (!store.offline.isOffline) {
      return volateqApi.uploadObservationImages(plantId, observationId, images, component, onUploadProgress);
    }

    if (!this.offlineDB) {
      throw new Error("OfflineDB disappeared");
    }

    const observations = await this.getOfflineData(DBOfflineStores.NEW_OBSERVATIONS(component), observationId);
    if (observations.length === 0) {
      throw new Error("Observation " + observationId + " not found");
    }

    const observation = observations[0];
    observation.image_urls = observation.image_urls || [];
    observation.request.file_ids = observation.request.file_ids || [];
    for (const file of images) {
      const dataUrl = await this.createDataUrl(file);
      observation.image_urls.push(dataUrl);

      const fileId = hash(dataUrl);
      observation.request.file_ids.push(fileId);
      observation.files_map = { ...(observation.files_map || {}), [fileId]: file.name };
    }

    await this.offlineDB.update(DBOfflineStores.NEW_OBSERVATIONS(component), observation);
  }

  public async deleteObservation(plantId: string, observationId: string, feature: FeatureLike): Promise<void> {
    if (!store.offline.isOffline) {
      return volateqApi.deleteObservation(plantId, observationId, feature);
    }

    if (!this.offlineDB) {
      throw new Error("OfflineDB disappeared");
    }

    const featureProps = feature.getProperties() as FeatureProperties;

    await this.offlineDB.delete(DBOfflineStores.NEW_OBSERVATIONS(featureProps.component), observationId);
    await this.offlineDB.delete(DBOfflineStores.NEW_OBSERVATIONS_GEO_JSON, observationId);
  }

  public getMe(): Promise<UserSchema> {
    return this.get(null, DBOfflineStores.ME, () => volateqApi.getMe());
  }

  public getPerformanceIndicators(technologyId: ApiTechnology): Promise<PerformanceIndicatorSchema[]> {
    return this.get(null, DBOfflineStores.PERFORMANCE_INDICATORS, () => volateqApi.getPerformanceIndicators(technologyId));
  }

  private async createDataUrl(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const img = document.createElement("img");
      img.onload = () => {
        try {
          const canvas = document.createElement("canvas");
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
  
          const ctx = canvas.getContext("2d")!;
          ctx.drawImage(img, 0, 0);
  
          resolve(canvas.toDataURL());
        } catch (e) {
          reject(e);
        }
      };
      img.crossOrigin = "Anonymous";
      img.src = URL.createObjectURL(file);
    });
  }
}