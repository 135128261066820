<template>
  <div>
    <strong>January 2025 (v9.1.0)</strong><br>
    <hr>
    <ul>
      <li><a href="#feature-1">New Popup-Style in Map view, that shows observations, always</a></li>
      <li><a v-if="isAdmin" href="#feature-2"><app-super-admin-marker />New Custom Component Property Types: <i>Analysis</i> and <i>Component</i></a></li>
      <li><a v-if="isAdmin" href="#feature-3"><app-super-admin-marker />Manage "Release Mail Recipients" under customers</a></li>
      <li><a href="#feature-4">Bug fixes and improvements</a></li>
    </ul>
    <hr>
    <div>
      <app-box>
        <h3 id="feature-1" class="grayed font-bold font-md mar-bottom">
          New Popup-Style in Map view, that shows observations, always
        </h3>
        <p>
          Tab-Style for "Performance Indicators" and "Observations"
          If you click the observations tab, all observations of the according component will be loaded.
        </p>
        <div class="mar-bottom-2x"></div>
      </app-box>
      <app-box v-if="isAdmin">
        <h3 id="feature-2" class="grayed font-bold font-md mar-bottom">
          New Custom Component Property Types: <i>Analysis</i> and <i>Component</i>
        </h3>
        <p class="">
          A Custom Component Property has a <b>Relation Type</b>, now. Those are:
          <ul>
            <li><b>Time</b>: That was the default so far. An observation accords to an component at a specific timestamp.</li>
            <li><b>Analysis</b>: For analysis specific data. An observation accords to an component of an analysis. E. g. "Could not be measured"-Reason</li>
            <li><b>Component</b>: Static property. An observation accords to an component - independent of time. E. g. A Link to another system</li>
          </ul>
        </p>
        <div class="mar-bottom-2x"></div>
      </app-box>
      <app-box>
        <h3 id="feature-3" class="grayed font-bold font-md mar-bottom">Manage "Release Mail Recipients" under customers</h3>
        <p>
          When editing a customer you can now choose the default list of release mail recipients for analyses of that customer. 
          CUSTOMER_ADMIN accounts are added automatically. Useful to add F2C accounts per customer to always receive the release mails as well.
        </p>
      </app-box>
      <app-box>
        <h3 id="feature-4" class="grayed font-bold font-md mar-bottom">Bug fixes and improvements</h3>
        <p>
          We fixed a few bugs.
        </p>
      </app-box>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseReleaseNotesComponent from "./base-release-notes-component"
import AppBox from "../shared/components/app-box/app-box.vue";
import AppSuperAdminMarker from "../shared/components/app-super-admin-marker/app-super-admin-marker.vue";

@Component({
  name: "app-release-notes-v15",
  components: {
    AppBox,
    AppSuperAdminMarker,
  }
})
export default class AppReleaseNotesV15 extends BaseReleaseNotesComponent {
  releaseNotesVersion = "v15"
}
</script>
