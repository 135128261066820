<template>
  <app-modal-form
    class="release-notes-modal"
    id="release-notes-modal"
    ref="appReleaseNotesModal"
    :title="$t('release-notes')"
    :subtitle="'v'+currentWebAppVersion"
    size="xl"   
    :okTitle="$t('ok')"
    :showCancelButton="false"
    @submit="onAcceptReleaseNotes"
    @cancel="onAcceptReleaseNotes"
  >
    <app-release-notes-v12 />
  </app-modal-form>
</template>

<script lang="ts">
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { version } from "../../../package.json";
import { userService } from "../shared/services/user-service/user-service";
import AppModalForm from "@/app/shared/components/app-modal/app-modal-form.vue";
import { IAppModalForm } from "@/app/shared/components/app-modal/types";
import { versionToNumber } from "@/app/shared/services/helper/version-helper";
import { CatchError } from '@/app/shared/services/helper/catch-helper';
import { UsedWebappVersionRequest } from "@/app/shared/services/volateq-api/api-requests/user-requests";
import AppReleaseNotesV12 from "./release-notes-v12.vue";
import { BaseAuthOnlineComponent } from "../shared/components/base-auth-component/base-auth-online-component";

@Component({
  name: "app-release-notes",
  components: {
    AppModalForm,
    AppReleaseNotesV12,
  },
})
export default class AppReleaseNotes extends BaseAuthOnlineComponent {
  @Ref() appReleaseNotesModal!: IAppModalForm;
  @Prop({ default: false }) showReleaseNotes!: boolean;

  readonly releasedWebAppVersion = "8.12.0";

  currentWebAppVersion: string = version;

  nothingNew = false;

  async mounted() {
    const me = await userService.me();
    
    const releasedVersion = versionToNumber(this.releasedWebAppVersion);
    const lastUsedVersion = versionToNumber(me.profile?.last_used_webapp_version);

    if (lastUsedVersion < releasedVersion && !this.nothingNew) {
      this.appReleaseNotesModal.show();
    }
  }

  @CatchError()
  async onAcceptReleaseNotes() {
    const currentWebappVersionRequest: UsedWebappVersionRequest = { used_webapp_version: this.currentWebAppVersion };
    await this.volateqApi.updateWebAppVersion(currentWebappVersionRequest);
    await userService.refreshMe();

    this.appReleaseNotesModal.hide();
  }

  @CatchError()
  @Watch('showReleaseNotes')
  onShowReleaseNotes() {
    if (this.showReleaseNotes) {
      this.appReleaseNotesModal.show();
    }
  }
}
</script>

<style lang="scss">

#release-notes-modal {
  .modal-content .modal-header {
    padding-bottom: 10px;
  }

  .app-modal-form-title-subtitle {
    font-size: 0.7em;
  }

  .modal-content .modal-body {
    padding-top: 0;
    max-height: 70vh;
    overflow-y: auto;
  }

  .modal-content ul {
    margin: 5px 20px;
  }
}
</style>
