export enum ApiPermissions {
  DOWNLOAD_ANALYSIS_FILE = 1,
  DELETE_ANALYSIS_FILE = 2,
  MOVE_ANALYSIS_FILE = 3,
  IMPORT_ANALYSIS_RESULT = 4,
  RELEASE_ANALYSIS_RESULT = 5,
  DELETE_ANALYSIS_RESULT = 6,
  READ_ANALYSIS_RESULT = 7,
  DOWNLOAD_ANALYSIS_RESULT_FILE = 8,
  UPDATE_ANALYSIS_RESULT = 9,
  READ_ANALYSIS = 10,
  CREATE_ANALYSIS = 11,
  DELETE_ANALYSIS = 12,
  UPDATE_ANALYSIS = 13,
  READ_ANALYSIS_HISTORY = 14,
  UPDATE_ANALYSIS_HISTORY = 15,
  UPDATE_ANALYSIS_STATE_MANUALLY = 16,
  READ_QFLY_SERVER = 17,
  RUN_QFLY_SERVER = 18,
  UPDATE_ANALYSIS_TASK = 19,
  READ_ANALYSIS_ORDER = 20,
  UPDATE_ANALYSIS_PRODCUT_PACKAGES = 21,
  READ_ANALYSIS_DRONE_PLANT_COVERAGE = 22,
  MANAGE_CUSTOM_COMPONENT_PROPERTY = 23,
  READ_CUSTOMER = 24,
  CREATE_CUSTOMER = 25,
  UPDATE_CUSTOMER = 26,
  DELETE_CUSTOMER = 27,
  UPLOAD_DOC_FILE = 28,
  LIST_DOC_FILE = 29,
  DOWNLOAD_DOC_FILE = 30,
  UPDATE_DOC_FILE = 31,
  DELETE_DOC_FILE = 32,
  CREATE_DRONE_MODEL = 33,
  READ_DRONE_MODEL = 34,
  UPDATE_DRONE_MODEL = 35,
  DELETE_DRONE_MODEL = 36,
  CREATE_DRONE = 37,
  READ_DRONE = 38,
  READ_AVAILABLE_DRONE = 39,
  UPDATE_DRONE = 40,
  DELETE_DRONE = 41,
  MANAGE_ENABLED_PI_FIELD = 42,
  UPLOAD_DIGITAL_TWIN = 43,
  DOWNLOAD_DIGITAL_TWIN = 44,
  CREATE_FLIGHT_CAMPAIGN = 45,
  READ_FLIGHT_CAMPAIGN = 46,
  UPDATE_FLIGHT_CAMPAIGN = 47,
  DELETE_FLIGHT_CAMPAIGN = 48,
  EXPORT_FLIGHT_CAMPAIGN = 49,
  READ_FLIGHT_ROUTE = 50,
  DELETE_FLIGHT_ROUTE = 51,
  READ_DIGITAL_TWIN_VISUALIZATION = 52,
  READ_ANALYSIS_RESULT_VISUALIZATION = 53,
  READ_OBSERVATION_VISUALIZATION = 54,
  READ_OBSERVATION = 55,
  CREATE_OBSERVATION = 56,
  UPDATE_OBSERVATION = 57,
  DELETE_OBSERVATION = 58,
  UPLOAD_OBSERVATION_IMAGE = 59,
  MOVE_REFERENCE_MEASUREMENT = 60,
  IGNORE_REFERENCE_MEASUREMENT = 61,
  READ_ORDER = 62,
  UPDATE_ORDER = 63,
  CREATE_ORDER = 64,
  DELETE_ORDER = 65,
  READ_ORDER_PRODUCT_PACKAGES = 66,
  READ_AVAILABLE_PLANT = 67,
  READ_ALL_PLANT = 68,
  CREATE_PLANT = 69,
  UPDATE_PLANT = 70,
  DELETE_PLANT = 71,
  MANAGE_PRODUCT_PACKAGE = 72,
  READ_RELEASE_NOTES = 73,
  STOP_QFLY_SERVER = 74,
  UPDATE_QFLY_SERVER = 75,
  READ_ALL_ANALYSIS_STATE = 76,
  READ_TASK = 77,
  UPLOAD_ANALYSIS_FILE = 78,
  CREATE_USER = 79,
  UPDATE_USER = 80,
  DELETE_USER = 81,
  READ_USER = 82,
  SWITCH_CUSTOMER = 83,
  READ_PERMISSION = 84,
  READ_AVAILABLE_ROLE = 85,
  OVERWRITE_OBSERVATION = 86,
  UPDATE_ANALYSIS_PROTECTED = 87,
  READ_PRODUCT_PACKAGE = 88,
  UPDATE_ANALYSIS_INVOICE = 89,
  READ_ANALYSIS_INVOICE = 90,
  IMPORT_EXTERNAL_FILES_FOR_ANALYSIS = 91,
  READ_FH2_PROJECTS = 92,
  READ_FH2_PROJECT_FLIGHT_ROUTES = 93,
  DELETE_FLIGHT_ROUTE_FH2 = 94,
  CREATE_REPORT = 95,
  READ_REPORT = 96,
  DELETE_OTHER_REPORT = 97,
  UPDATE_OWN_CUSTMER = 98,
  READ_RUNPOD_SERVER = 99,
  START_RUNPOD_SERVER = 100,
  STOP_RUNPOD_SERVER = 101,
}
