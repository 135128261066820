import { FindUser, InviteUser, RegisterUser, UsedWebappVersionRequest } from "@/app/shared/services/volateq-api/api-requests/user-requests";
import { TokenResult } from "@/app/shared/services/volateq-api/api-schemas/auth-schema";
import { CustomerNameSchema, CustomerSchema, OnlyOwnCustomerSchema } from "@/app/shared/services/volateq-api/api-schemas/customer-schemas";
import { SimpleUserRoleSchema, SimpleUserSchema, UserAuthMethod, UserSchema } from "@/app/shared/services/volateq-api/api-schemas/user-schemas";
import { HttpClientBase } from "@/app/shared/services/volateq-api/http-client-base";
import { apiBaseUrl, baseUrl } from "@/environment/environment";
import { NewEmptyAnalysisRequest, UpdateAnalysisInvoiceRequest, UpdateAnalysisRequest, UpdateAnalysisStateRequest } from "./api-requests/analysis-requests";
import { ClonePlantRequest, CreatePlantRequest, UpdatePlantRequest } from "./api-requests/plant-requests";
import { AnalysisFileInfoSchema, AnalysisForViewSchema, AnalysisNameCustomerPlantSchema, AnalysisSchema, SimpleAnalysisSchema } from "./api-schemas/analysis-schema";
import { PlantSchema } from "./api-schemas/plant-schema";
import { AnalysisResultChangeHistorySchema, AnalysisResultDetailedSchema, AnalysisResultHistorySchema } from "./api-schemas/analysis-result-schema";
import { TableFilterRequest, TableRequest } from "./api-requests/common/table-requests";
import { AnalysisResultFileSchema } from "./api-schemas/analysis-result-file-schema";
import { ApiComponent } from "./api-components/api-components";
import { ApiKeyFigure } from "./api-key-figures";
import { GeoVisualCspPtcQuery, GeoVisualPvQuery, GeoVisualQuery } from "./api-requests/geo-visual-query-requests";
import { TableResultSchema } from "./api-schemas/table-result-schema";
import { TaskSchema } from "./api-schemas/task-schema";
import { ApiStates } from "./api-states";
import { CreateCustomerRequest, UpdateCustomerRequest, UpdateOnlyOwnCustomerRequest } from "./api-requests/customer-requests";
import { TechnologySchema } from "./api-schemas/technology-schema";
import { AnalysisMonitoring } from "./api-schemas/analysis-monitoring";
import { QFlyServerSchema, RunpodServerSchema } from "./api-schemas/server-schemas";
import { QFlyServerUpdateRequest } from "./api-requests/server-requests";
import { DocFile } from "./api-schemas/doc-file-schema";
import { ProductPackageWithKeyFiguresSchema } from "./api-schemas/product-package";
import { CreateOrderRequest, UpdateOrderRequest } from "./api-requests/order-requests";
import { OrderPPKeyFiguresDisabledSchema, OrderProductPackageSchema, OrderSchema } from "./api-schemas/order-schema";
import { MultiselectOption } from "../../components/app-multiselect/types";
import { SecuredFilename, Upload, UploadChunkResult } from "./api-schemas/upload-schemas";
import { CreateAnalysisUploadRequest } from "./api-requests/upload-requests";
import { ModfifyAnalysisResultValueRequest } from "./api-requests/analysis-result-requests";
import { KeyFigureSchema } from "./api-schemas/key-figure-schema";
import { CreateProductPackageRequest, UpdateProductPackageRequest } from "./api-requests/product-package-requests";
import { PlantStatusSchema } from "./api-schemas/plant-status-schema";
import { CreateDroneModelRequest, UpdateDroneModelRequest, CreateDroneRequest, UpdateDroneRequest } from "./api-requests/drone-requests";
import { DroneModelSchema, DroneSchema } from "./api-schemas/drone-schemas";
import { FlightCampaignSchema } from "./api-schemas/flight-campaign-schema";
import { CreateFlightCampaignRequest, UpdateFlightCampaignRequest } from "./api-requests/flight-campaign-requests";
import { FlightRouteMissionSchema, FlightRouteSchema } from "./api-schemas/flight-route-schema";
import { PlantOperationActionSchema } from "./api-schemas/plant-operation-action-schema";
import { CustomComponentPropertySchema } from "./api-schemas/custom-component-property-schema";
import { CustomComponentPropertyRequest } from "./api-requests/custom-component-property-request";
import { DeleteObservationComponentsRequest, ObservFilterValue, ObservationComponentsRequest, ObservationFilterRequest, ObservationsRequest, SummerizedObservationRequest } from "./api-requests/observation-requests";
import { CreatedObservationSchema, ObservationColumn, ObservationSchema, ReferenceMeasurementsSchema, SummerizedDates } from "./api-schemas/observation-schema";
import { ObservationRequest } from "./api-requests/observation-requests";
import { DisablePiFieldsRequest, EnablePiFieldsRequest } from "./api-requests/enabled-pi-field-requests";
import { EnabledPiFieldSchema } from "./api-schemas/enabled-pi-field-schema";
import { IVolateqOfflineApi } from "./offline/types";
import { FeatureLike } from "ol/Feature";
import { store } from "../../stores/store";
import { RoleSchema } from "./api-schemas/role-schema";
import { PermissionsMatrixSchema } from "./api-schemas/permission-schema";
import { InvoiceStateSchema } from "./api-schemas/invoice-state-schema";
import { CreateReportRequest } from "./api-requests/report-request";
import { ReportSchema } from "./api-schemas/report-schema";
import { ImportFilesFromFlighthub2Request, ExportFlightCampaignFlighthub2Request } from "./api-requests/flighthub2-requests";
import { Flighthub2FlightRouteSchema, Flighthub2FolderSchema, Flighthub2ProjectSchema, Flighthub2TaskPlanSchema } from "./api-schemas/flighthub2-schema";
import { AxiosProgressEvent } from "axios";
import { PerformanceIndicatorSchema } from "./api-schemas/performance-indicator";
import { ApiPI } from "./api-performance-indicator";
import { ApiTechnology } from "./api-technologies";
import { UnleashFolderSchema } from "./api-schemas/unleash-schema";
import { AnalysisResultReleaseNotesSchema } from "./api-schemas/analysis-result-release-notes-schema";
import { AnalysisResultReleaseMailSchema } from "./api-schemas/analysis-result-release-mail-schema";
import { ImportFilesFromUnleashRequest } from "./api-requests/unleash-requests";
import { FlightRoute } from "@/app/analysis/edit-analysis/flight-campaign/types";

export class VolateqAPI extends HttpClientBase implements IVolateqOfflineApi {
  //
  // LOGIN, LOGOUT, REGISTER, CONFIRM, ... - All "Auth" actions 
  //

  public async login(email: string, password: string): Promise<{ confirmation_key: string, auth_method: UserAuthMethod }> {
    return await this.post(
      "/auth/login",
      {},
      {
        auth: {
          username: email,
          password: password,
        },
      }
    );
  }

  public async isLoggedIn(): Promise<boolean> {
    if (store.auth.isAuthenticated) {
      try {
        const pong: { pong: true } = await this.get("/auth/ping");

        if (pong.pong) {
          return true;
        }
      } catch {
        return false;
      }
    }

    return false;
  }

  public async confirmMailLogin(confirmationKey: string, securityCode: string): Promise<void> {
    const tokenResult: TokenResult = await this.post(`/confirm-email-login/${confirmationKey}`, {
      security_code: securityCode,
    });

    store.auth.updateToken({
      token: tokenResult.token,
      permissions: tokenResult.permissions,
      customer: tokenResult.customer,
      ...(tokenResult.is_volateq_admin ? { isAdmin: tokenResult.is_volateq_admin } : {}),
    });
  }

  public async confirmTotpLogin(confirmationKey: string, securityCode: string): Promise<void> {
    const tokenResult: TokenResult = await this.post(`/confirm-totp-login/${confirmationKey}`, {
      security_code: securityCode,
    });

    store.auth.updateToken({
      token: tokenResult.token,
      permissions: tokenResult.permissions,
      isAdmin: tokenResult.is_volateq_admin,
      ...(tokenResult.is_volateq_admin ? { isAdmin: tokenResult.is_volateq_admin } : {}),
    });
  }

  public async logout(): Promise<void> {
    await this.post("/auth/logout");

    store.auth.updateToken({ token: "", permissions: [], customer: undefined });
  }

  public async getInvitedUser(confirmKey: string): Promise<UserSchema> {
    return this.get(`/register/${confirmKey}`);
  }

  public async registerUser(confirmKey: string, user: RegisterUser): Promise<{ confirmation_key?: string }> {
    return await this.post(`/register/${confirmKey}`, user);
  }

  public forgotPassword(email: string): Promise<void> {
    return this.post(`/forgot-password`, { email });
  }

  public confirmPasswordReset(confirmationKey: string): Promise<void> {
    return this.get(`/reset-password/${confirmationKey}`);
  }

  public resetPassword(confirmationKey: string, new_password: string, new_password_repeat: string): Promise<void> {
    return this.post(`/reset-password/${confirmationKey}`, { new_password, new_password_repeat });
  }

  public async resendSecurityCode(confirmationKey: string): Promise<void> {
    await this.post(`/confirm-email-login-resend/${confirmationKey}`, {});
  }

  public async getTotpSecrets(confirmationKey: string): Promise<{ url: string, secret: string }> {
    return await this.get(`/totp-secrets/${confirmationKey}`);
  }

  public async verifyTotpCode(confirmationKey: string, securityCode: string): Promise<void> {
    await this.post(`/totp-secrets/${confirmationKey}`, { security_code: securityCode });
  }

  public async cancelSetupTotpMfa(confirmationKey: string): Promise<void> {
    await this.delete(`/totp-secrets/${confirmationKey}`);
  }

  //
  // User
  //

  public async getMe(): Promise<UserSchema> {
    return await this.get("/auth/user/me");
  }

  public async getUsers(filter: FindUser): Promise<TableResultSchema<UserSchema>> {
    return this.get("/auth/users", filter);
  }

  public async getVolateqUsers(): Promise<SimpleUserRoleSchema[]> {
    return this.get("/auth/volateq-users");
  }

  public async inviteUser(user: InviteUser): Promise<string> {
    const confirmKey = (await this.post("/auth/user", user)).confirmation_key;

    return `${baseUrl}/register/${confirmKey}`;
  }

  public async assignPlantsToUser(userId: string, plantIds: string[]): Promise<void> {
    await this.post(`/auth/user/${userId}/assign-plants`, { plant_ids: plantIds });
  }

  public async changeUserRole(userId: string, roleId: number, securityCode?: string): Promise<void> {
    await this.post(`/auth/user/${userId}/change-role`, { role_id: roleId, security_code: securityCode });
  }

  public async deleteUser(userId): Promise<void> {
    await this.delete(`/auth/user/${userId}/delete`);
  }

  public async generateDownloadUrl(downloadUrl: string): Promise<string> {
    const concatURLTokenSign = downloadUrl.includes("?") ? "&" : "?";

    const urlTokenResponse: { url_token: string } = await this.post(
      `/auth/user/generate-url-token`,
      { url: encodeURIComponent(downloadUrl) }
    );

    return `${downloadUrl}${concatURLTokenSign}url_token=${encodeURIComponent(urlTokenResponse.url_token)}`;
  }

  public resendUserInvitation(userId: string): Promise<void> {
    return this.post(`/auth/user/${userId}/resend-invitation`);
  }

  public unLockUser(userId: string, lock: boolean): Promise<void> {
    return this.post(`/auth/user/${userId}/un-lock`, { lock });
  }

  public async switchCustomer(toCustomerId: string | undefined, showAllKeyFigures: boolean): Promise<CustomerNameSchema> {
    return this.post(`/auth/user/switch-customer`, { customer_id: toCustomerId, show_all_key_figures: showAllKeyFigures });
  }

  public async prepareUserChangeAuthMethod(authMethod: UserAuthMethod, password: string): Promise<{confirmation_key: string}> {
    return await this.post(`/auth/user/prepare-change-auth-method`, { auth_method: authMethod, password });
  }

  public async changeUserAuthMethod(authMethod: UserAuthMethod, confirmationKey: string, securityCode: string, newSecurityCode: string): Promise<void> {
    await this.post(`/auth/user/change-auth-method/${confirmationKey}`, { 
      auth_method: authMethod,
      security_code: securityCode,
      new_security_code: newSecurityCode,
    });
  }

  public async updateWebAppVersion(usedWebappVersionRequest: UsedWebappVersionRequest) {
    await this.post(`/auth/user/update-app-version`, usedWebappVersionRequest );
  }

  public async getRoles(): Promise<RoleSchema[]> {
    return this.get(`/auth/user/roles`)
  }

  public async getPermissions(): Promise<PermissionsMatrixSchema> {
    return this.get(`/auth/user/permissions`)
  }

  //
  // Analyis
  //

  public async createEmptyAnalysis(newEmptyAnalyis: NewEmptyAnalysisRequest): Promise<{ id: string }> {
    return this.post(`/auth/analysis/create-empty`, newEmptyAnalyis);
  }

  public async getAllAnalyses(
    queryParams?: { plant_id?: string }
  ): Promise<AnalysisSchema[]> {
    return this.get(`/auth/analysis`, queryParams);
  }

  public async getAllAnalysesNameCustomerPlant(
    queryParams?: { plant_id?: string }
  ): Promise<AnalysisNameCustomerPlantSchema[]> {
    return this.get(`/auth/analysis-name-customer-plant`, queryParams);
  }

  public async getAnalyses(
    queryParams?: { plant_id?: string, technology_id?: string } & TableRequest & { limit: number },
  ): Promise<TableResultSchema<AnalysisSchema>> {
    return this.get(`/auth/analysis`, queryParams);
  }

  public async getIncompleteAnalyses(queryParams?: { plant_id?: string, technology_id?: string }): Promise<AnalysisSchema[]> {
    return this.get(`/auth/analyses/incomplete`, queryParams);
  }

  public getAnalysis(analysisId?: string): Promise<AnalysisSchema> {
    return this.get(`/auth/analysis/${analysisId}`);
  }

  public async getAnalysesForView(plantId: string): Promise<AnalysisForViewSchema[]> {
    const analyses: AnalysisForViewSchema[] = await this.get(`/auth/analyses-for-view/${plantId}`);

    for (const analysis of analyses) {
      if (analysis.analysis_result) {
        this.replaceKeyFigureIRWithGlassTubeTemp(analysis.analysis_result);
      }
    }

    return analyses;
  }

  public async updateAnalysisState(analysisId: string, analysisState: UpdateAnalysisStateRequest): Promise<void> {
    await this.post(`/auth/analysis/${analysisId}/state`, analysisState);
  }

  public getStates(): Promise<Record<ApiStates, ApiStates[]>> {
    return this.get(`/auth/states`);
  }

  public async deleteAnalysis(analysisId: string): Promise<void> {
    await this.delete(`/auth/analysis/${analysisId}`);
  }

  public getAnalysisNotesAndHistory(analysisId: string): Promise<{ notes_and_history: string }> {
    return this.get(`/auth/analysis/${analysisId}/notes-and-history`);
  }

  public appendAnalysisNote(analysisId: string, note: string): Promise<void> {
    return this.post(`/auth/analysis/${analysisId}/notes-and-history`, { note });
  }

  public async updateAnalysis(
    analysisId: string,
    updateData: UpdateAnalysisRequest,
  ): Promise<void> {
    await this.post(`/auth/analysis/${analysisId}`, updateData);
  }

  public getAnalysisFileDownloadUrl(analysisId: string, fileName: string): Promise<{ url: string }> {
    return this.get(`/auth/analysis/${analysisId}/file/${fileName}`);
  }

  public downloadMultipleAnalysisFilesUrl(analysisId: string, filenames: string[] | "all") {
    return this.getUrl(`${apiBaseUrl}/auth/analysis/${analysisId}/files/download`, { filenames: filenames });
  }

  public getAnalysisFiles(analysisId: string, filenames: string[]): Promise<AnalysisFileInfoSchema> {
    return this.post(`/auth/analysis/${analysisId}/files`, { file_names: filenames });
  }

  public async deleteAnalysisFiles(analysisId: string, filenames: string[]): Promise<void> {
    await this.post(`/auth/analysis/${analysisId}/files/delete`, { file_names: filenames });
  }

  public async moveAnalysisFiles(analysisId: string, targetAnalysisId: string, filenames: string[]): Promise<void> {
    await this.post(`/auth/analysis/${analysisId}/files/move`, {
      file_names: filenames,
      target_analysis_id: targetAnalysisId,
    });
  }

  public getAnalysisMonitoring(): Promise<AnalysisMonitoring> {
    return this.get(`/auth/analysis-monitoring`).then(
      response =>
        typeof response == "string"
          ? {}
          : response /* The 204 (NO CONTENT) response from the backend causes response to be a string */
    );
  }

  public async finishRunningTask(analysisId: string): Promise<void> {
    await this.post(`/auth/analysis/${analysisId}/finish-running-task`);
  }

  public async getUploadingUsers(analysisId: string): Promise<SimpleUserSchema[]> {
    return this.get(`/auth/analysis/${analysisId}/uploading-users`);
  }

  public async getLastAnalysis(plantId: string, lastDate?: string): Promise<SimpleAnalysisSchema> {
    return await this.get(`/auth/last-analysis/${plantId}`, lastDate && { date: lastDate });
  }

  // Analysis invoice

  public async getAvailableInvoiceStates(): Promise<InvoiceStateSchema[]> {
    return this.get(`/auth/invoice-states`);
  }

  public async updateAnalysisInvoice(
    analysisId: string,
    updateData: UpdateAnalysisInvoiceRequest
  ): Promise<void> {
    await this.post(`/auth/analysis/${analysisId}/invoice`, updateData);
  }

  //
  // Analysis result
  //

  public async importAnalysisResult(
    jsonFile: File | null,
    analysisId: string,
    imageFilesZipName?: string,
    deleteBefore?: boolean,
  ): Promise<TaskSchema> {
    const formData = {};
    if (jsonFile) {
      formData["json_file"] = jsonFile;
    }
    if (imageFilesZipName) {
      formData["images_zip_filename"] = imageFilesZipName;
    }
    if (deleteBefore) {
      formData["delete_before"] = 1
    }

    return await this.postForm(`/auth/import-analysis-result/${analysisId}`, formData);
  }

  public async uploadImportAnalysisResultImageFiles(
    analysisId: string,
    imageFilesZip: File,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<void> {
    await this.postForm(
      `/auth/import-analysis-result/${analysisId}/upload-images`, 
      { image_files_zip: imageFilesZip },
      onUploadProgress
    );
  }

  public getSpecificAnalysisResult<T>(
    analysisResultId: string,
    componentId: number,
    params: TableRequest,
    filterParams?: TableFilterRequest
  ): Promise<TableResultSchema<T>> {
    return this.get(
      `/auth/analysis-result/${analysisResultId}/${componentId}${this.getQueryParams(
        params
      )}${this.getEncodedAnalysisResultFilterParams(filterParams)}`
    );
  }

  public getSpecificAnalysisResultDTIdsFiltered<T>(
    analysisResultId: string,
    componentId: number,
    params: TableRequest,
    dtIds: string[],
    filterParams?: TableFilterRequest,
  ): Promise<TableResultSchema<T>> {
    return this.post(
      `/auth/analysis-result/${analysisResultId}/${componentId}${this.getQueryParams(
        params
      )}${this.getEncodedAnalysisResultFilterParams(filterParams)}`,
      { dt_ids: dtIds },
    );
  }

  public getSpecificAnalysisResultCsvUrl(
    analysisResultId: string,
    componentId: number,
    params: TableRequest,
    filterParams?: TableFilterRequest,
    csvMappings?: { [key: string]: string }
  ): string {
    const encodedCsvMappings =
      (csvMappings && `&csv_mappings=${encodeURIComponent(this.getQueryParams(csvMappings).substring(1))}`) || "";

    return `${apiBaseUrl}/auth/analysis-result/${analysisResultId}/${componentId}${this.getQueryParams(
      params
    )}&csv=1${encodedCsvMappings}${this.getEncodedAnalysisResultFilterParams(filterParams)}`;
  }

  public getSpecificAnalysisResultComparedCsvUrl(
    analysisResultId: string,
    componentId: number,
    compareAnalysisResultId: string,
    params: TableRequest,
    filterParams?: TableFilterRequest,
    csvMappings?: { [key: string]: string }
  ): string {
    const encodedCsvMappings =
      (csvMappings && `&csv_mappings=${encodeURIComponent(this.getQueryParams(csvMappings).substring(1))}`) || "";

    return `${apiBaseUrl}/auth/analysis-result/compare/${analysisResultId}/${componentId}/${compareAnalysisResultId}${this.getQueryParams(
      params
    )}&csv=1${encodedCsvMappings}${this.getEncodedAnalysisResultFilterParams(filterParams)}`;
  }

  public getSpecificAnalysisResultCompared<T>(
    analysisResultId: string,
    componentId: number,
    compareAnalysisResultId: string,
    params: TableRequest,
    filterParams?: TableFilterRequest
  ): Promise<TableResultSchema<T>> {
    return this.get(
      `/auth/analysis-result/compare/${analysisResultId}/${componentId}/${compareAnalysisResultId}${this.getQueryParams(
        params
      )}${this.getEncodedAnalysisResultFilterParams(filterParams)}`
    );
  }

  public getAnalysisResultHistory<T>(
    plantId: string,
    componentId: number,
    params: TableRequest,
    filterParams?: TableFilterRequest,
    keyFigureId?: ApiKeyFigure,
  ): Promise<TableResultSchema<AnalysisResultHistorySchema>> {
    return this.get(`/auth/analysis-results/history/${plantId}/${componentId}${this.getQueryParams(
        keyFigureId ? { key_figure_id: keyFigureId, ...params } : params
      )}
      ${this.getEncodedAnalysisResultFilterParams(filterParams)}`);
  }

  public getAnalysisResultHistoryUnlimited(
    plantId: string,
    componentId: number,
    params: TableRequest & { limit?: undefined },
    filterParams?: TableFilterRequest,
    keyFigureId?: ApiKeyFigure,
  ): Promise<AnalysisResultHistorySchema[]> {
    return this.getAnalysisResultHistory(
      plantId,
      componentId,
      params,
      filterParams,
      keyFigureId,
    ) as unknown as Promise<AnalysisResultHistorySchema[]>;
  }

  public getAnalysisResultFiles(analysisResultId: string): Promise<AnalysisResultFileSchema[]> {
    return this.get(`/auth/analysis-result/${analysisResultId}/files`);
  }

  public async getAnalysisResults(plantId: string, allKeyFigures = false): Promise<AnalysisResultDetailedSchema[]> {
    const analysisResults = await this.get(
      `/auth/plant/${plantId}/analysis-results`, 
      allKeyFigures ? { all_key_figures: 1 } : undefined
    );

    for (const analysisResult of analysisResults) {
      this.replaceKeyFigureIRWithGlassTubeTemp(analysisResult);
    }

    return analysisResults;
  }

  public updateAnalysisResult(analysisResultId: string, updates: { release: boolean }): Promise<void> {
    return this.post(`/auth/analysis-result/${analysisResultId}`, updates);
  }

  public getAnalysisResultFileUrl(analysisResultFileId: string): Promise<{ url: string }> {
    return this.get(`/auth/analysis-result/result-file/${analysisResultFileId}`);
  }

  public async modifyAnalysisResultValue(analysisResultId: string, modifyRequest: ModfifyAnalysisResultValueRequest): Promise<void> {
    await this.post(`/auth/analysis-result/${analysisResultId}/modify-value`, modifyRequest);
  }

  public async getAnalysisResultChangeHistory(analysisResultId: string): Promise<AnalysisResultChangeHistorySchema[]> {
    return await this.get(`/auth/analysis-result/${analysisResultId}/change-history`);
  }

  public async undoAnalysisResultValueChange(analysisResultChangeHistoryId: string): Promise<void> {
    await this.post(`/auth/analysis-result-change-history/${analysisResultChangeHistoryId}/undo`);
  }

  public async isAnalysisResultUnmeasuredHolder(analysisResultId: string): Promise<{ is_unmeasured_holder: boolean }> {
    return await this.get(`/auth/analysis-result/${analysisResultId}/is-unmeasured-holder`);
  }

  public async refreshUnmeasuredOfNewerAnalyses(analysisResultId: string): Promise<void> {
    await this.post(`/auth/analysis-result/${analysisResultId}/refresh-unmeasured-of-newer-analyses`);
  }

  //
  // Analysis result release
  //

  public async setAnalysisReleaseNotes(analysisResultId: string, releaseNotes: string): Promise<void> {
    await this.postForm(`/auth/analysis-result/${analysisResultId}/release-notes`, { release_notes: releaseNotes });
  }

  public async getAnalysisReleaseNotes(analysisResultId: string): Promise<AnalysisResultReleaseNotesSchema | null> {
    return this.get(`/auth/analysis-result/${analysisResultId}/release-notes`);
  }

  public async getAnalysisReleaseNotesUsers(analysisResultId: string): Promise<SimpleUserRoleSchema[]> {
    return this.get(`/auth/analysis-result/${analysisResultId}/release-notes-users`);
  }

  public async sendAnalysisReleaseNotes(analysisResultId: string, userIds: string[]): Promise<void> {
    await this.post(`/auth/analysis-result/${analysisResultId}/send-release-mail`, { recipient_user_ids: userIds })
  }

  public async getSentAnalysisReleaseMails(analysisResultId: string): Promise<AnalysisResultReleaseMailSchema[]> {
    return this.get(`/auth/analysis-result/${analysisResultId}/sent-release-mails`)
  }

  public getAnalysisResultOrthomosaicTileXYZUrl(analysisResultId: string, keyFigureId: number, urlIndex: number): string {
    return `${apiBaseUrl}/auth/orthomosaic/analysis-result/${analysisResultId}/${keyFigureId}/${urlIndex}/{x}/{y}/{z}.png`;
  }

  public async getAnalysisResultOrthomosaicUrlsToken(analysisResultId: string, keyFigureId: number): Promise<string> {
    return this.get(`/auth/orthomosaic/analysis-result/${analysisResultId}/${keyFigureId}/urls`);
  }

  public async getAnalysisResultOrthomosaicTile(tileXYZUrl: string, imageUrlsToken: string): Promise<Blob> {
    return this.post(tileXYZUrl, { url: imageUrlsToken }, { responseType: "blob" });
  }

  //
  // Task
  //
  
  public getTask(taskId: string): Promise<TaskSchema> {
    return this.get(`/auth/task/${taskId}`);
  }

  public waitForTask(
    taskId: string,
    onFinished: (task: TaskSchema, failed: boolean) => void,
    onProgress?: (task: TaskSchema) => void,
    intervalMs = 3000,
  ): any {
    const interval = setInterval(async () => {
      const task = await this.getTask(taskId);
      if (task.state === "SUCCESSFUL" || task.state === "FAILED") {
        clearInterval(interval);

        const failed = task.state !== "SUCCESSFUL";
        if (failed) {
          if (!task.output) {
            task.output = {};
          }
          if (!task.output.error) {
            task.output.error = "No error message provided. Check server logs for details";
          }
        }

        onFinished(task, failed);
      } else if (onProgress) {
        onProgress(task);
      }
    }, intervalMs);

    return interval;
  }

  public getTaskOutputAsMessage(task: TaskSchema, defaultMsg = "") {
    if (task.output?.infos && task.output.infos.length > 0) {
      return "> " +
        task.output.infos.join("<br>> ") +
        ((task.output.max_steps && `... (${task.output.step}/${task.output.max_steps})`) || "...");
    }

    return defaultMsg;
  }
  
  //
  // Plant
  //
  
  public getPlant(plantId: string): Promise<PlantSchema> {
    return this.get(`/auth/plant/${plantId}`);
  }

  public getPlants(withAnalysisResultsCount = false, withOrders = false): Promise<PlantSchema[]> {
    return this.get(`/auth/plants`, {
      with_analysis_results_count: withAnalysisResultsCount ? 1 : 0,
      with_orders: withOrders ? 1 : 0,
    });
  }

  public getAllPlants(): Promise<PlantSchema[]> {
    return this.get(`/auth/plants/all`);
  }

  public updatePlant(plantId: string, updatePlantRequest: UpdatePlantRequest): Promise<void> {
    return this.post(`/auth/plant/${plantId}`, updatePlantRequest);
  }

  public async createPlant(createPlantRequest: CreatePlantRequest): Promise<void> {
    await this.post(`/auth/plant`, createPlantRequest);
  }

  public async deletePlant(plantId: string): Promise<void> {
    await this.delete(`/auth/plant/${plantId}`);
  }

  public getComponentsInPlant(plantId: string): Promise<number[]> {
    return this.get(`/auth/plant/${plantId}/existing-components`);
  }

  public getAllSubfieldsOfPlant(plantId: string): Promise<string[]> {
    return this.get(`/auth/plant/${plantId}/subfields`);
  }

  public getAllPlantStatus(): Promise<PlantStatusSchema[]> {
    return this.get('/auth/plant-status');
  }

  public async getCustomComponentProperties(plantId: string): Promise<CustomComponentPropertySchema[]> {
    return await this.get(`/auth/plant/${plantId}/custom-component-properties`);
  }

  public async createCustomComponentProperty(plantId: string, ccpRequest: CustomComponentPropertyRequest) {
    await this.post(`/auth/plant/${plantId}/custom-component-property`, ccpRequest);
  }
  
  public async updateCustomComponentProperty(plantId: string, ccpId: string, ccpRequest: CustomComponentPropertyRequest) {
    await this.post(`/auth/plant/${plantId}/custom-component-property/${ccpId}`, ccpRequest);
  }

  public async deleteCustomComponentProperty(plantId: string, ccpId: string) {
    await this.delete(`/auth/plant/${plantId}/custom-component-property/${ccpId}`)
  }

  public getReleaseNoteImageUrl(release_notes_version: string, file_name: string): Promise<{ url: string }> {
    return this.get(`/auth/release-notes/${release_notes_version}/${file_name}`)    
  }

  public async enablePiFields(plantId: string, enablePiFieldsRequest: EnablePiFieldsRequest) {
    await this.post(`/auth/plant/${plantId}/enabled-pi-fields`, enablePiFieldsRequest);
  }

  public async disablePiFields(plantId: string, disablePiFieldsRequest: DisablePiFieldsRequest) {
    await this.post(`/auth/plant/${plantId}/disabled-pi-fields`, disablePiFieldsRequest);
  }

  public async getEnabledPiFields(plantId: string): Promise<EnabledPiFieldSchema[]> {
    return await this.get(`/auth/plant/${plantId}/enabled-pi-fields`);
  }

  //
  // Keyfigures, Technology
  //

  public getAllKeyFigures(): Promise<KeyFigureSchema[]> {
    return this.get('/auth/key-figures');
  }

  private replaceKeyFigureIRWithGlassTubeTemp(analysisResult: AnalysisResultDetailedSchema): void {
    // IR_INTENSITY: Replaced by GLASS_TUBE_TEMPERATURE
    const ir_intensity_index = analysisResult.key_figures.findIndex(
      keyFigure => keyFigure.id === ApiKeyFigure.IR_INTENSITY_ID
    );
    if (
      ir_intensity_index != -1 &&
      analysisResult.key_figures.find(keyFigure => keyFigure.id === ApiKeyFigure.GLASS_TUBE_TEMPERATURE_ID)
    ) {
      analysisResult.key_figures.splice(ir_intensity_index, 1);
    }
  }

  public async getTechnologies(): Promise<TechnologySchema[]> {
    return await this.get(`/auth/technologies`);
  }

  public async getPerformanceIndicators(technologyId: ApiTechnology): Promise<PerformanceIndicatorSchema[]> {
    return await this.get(`/auth/performance-indicators/${technologyId}`);
  }

  //
  // Geo visualization
  //

  public getComponentsGeoVisual(
    plantId: string,
    componentIds: ApiComponent[],
    extent?: { min_long: number, max_long: number, min_lat: number, max_lat: number },
  ): Promise<any> {
    return this.get(`/auth/geo-visual/${plantId}/components`, { ids: componentIds, ...(extent ? extent : {}) });
  }

  public getComponentsGeoVisualCsv(
    plantId: string,
    componentIds: ApiComponent[],
  ): Promise<string> {
    return this.get(`/auth/geo-visual/${plantId}/components-csv`, { ids: componentIds }, { responseType: "text" });
  }

  public getKeyFiguresGeoVisual(
    plantId: string,
    analysisResultId: string,
    keyFiguresId: ApiKeyFigure,
    query_params?: GeoVisualCspPtcQuery | GeoVisualPvQuery
  ): Promise<any> {
    return this.get(`/auth/geo-visual/${plantId}/${analysisResultId}/key-figure/${keyFiguresId}`, query_params);
  }

  public getGeoVisualDTIdsFiltered(
    plantId: string,
    analysisResultId: string,
    keyFiguresId: ApiKeyFigure,
    query_params?: GeoVisualCspPtcQuery | GeoVisualPvQuery,
    dtIds?: string[],
  ): Promise<any> {
    return this.post(
      `/auth/geo-visual/${plantId}/${analysisResultId}/key-figure/${keyFiguresId}`, 
      (dtIds ? { dt_ids: dtIds } : {}),
      undefined,
      query_params,
    );
  }

  public getKeyFiguresGeoVisualCompare(
    plantId: string,
    analysisResultId: string,
    compareAnalysisResultId: string,
    keyFiguresId: ApiKeyFigure,
    query_params?: GeoVisualQuery
  ): Promise<any> {
    return this.get(
      `/auth/geo-visual/compare/${plantId}/${analysisResultId}/${compareAnalysisResultId}/key-figure/${keyFiguresId}`,
      query_params
    );
  }

  public getAnalysisResultGeoVisual(
    plantId: string,
    analysisResultId: string,
    componentId: number,
    params: TableRequest,
    filterParams?: TableFilterRequest,
    compareAnalysisResultId?: string,
  ): Promise<any> {
    return this.get(
      `/auth/geo-visual/analysis-result/${plantId}/${analysisResultId}/component/${componentId}${this.getQueryParams(
        { ...params, ...(compareAnalysisResultId ? { compare_analysis_result_id: compareAnalysisResultId } : {}) }
      )}${this.getEncodedAnalysisResultFilterParams(filterParams)}`
    );
  }

  //
  // Fieldgeometry - DT
  //

  public importFieldgeometry(
    file: File, 
    plantId: string, 
    clearBefore: boolean,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<TaskSchema> {
    return this.postForm(`/auth/fieldgeometry/${plantId}?clear_before=${clearBefore}`, { file }, onUploadProgress);
  }

  public getFieldgeometryFileUrl(fieldgeometryId: string): Promise<{ url: string }> {
    return this.get(`/auth/fieldgeometry/${fieldgeometryId}/file-url`);
  }

  public cloneFieldgeometry(
    plantId: string,
    clonePlantRequest: ClonePlantRequest,
  ): Promise<TaskSchema> {
    return this.post(`/auth/fieldgeometry/${plantId}/clone`, clonePlantRequest);
  }

  //
  // VolaFly
  //

  public downloadVolaFlyAppUrl(sdk_version: string): Promise<{ url: string, version: string }> {
    return this.get(`${apiBaseUrl}/volafly-download/${sdk_version}`)    
  }

  //
  // Customer
  //

  public getCustomers(withLogo?: boolean): Promise<CustomerSchema[]> {
    return this.get(`/auth/customers`, withLogo ? { with_logo: 1 } : {});
  }

  public async createCustomer(customerRequest: CreateCustomerRequest): Promise<{ id: string }> {
    return await this.post(`/auth/customer`, customerRequest);
  }

  public async updateCustomer(customerId: string, customerRequest: UpdateCustomerRequest): Promise<void> {
    await this.post(`/auth/customer/${customerId}`, customerRequest);
  }

  public async deleteCustomer(customerId: string): Promise<void> {
    await this.delete(`/auth/customer/${customerId}`);
  }

  public async setCustomerLogo(customerId: string, logo: File, onUploadProgressEvent?: (progressEvent: AxiosProgressEvent) => void) {
    await this.postForm(`/auth/customer/${customerId}/logo`, { logo }, onUploadProgressEvent);
  }

  public async updateOnlyOwnCustomer(customerRequest: UpdateOnlyOwnCustomerRequest): Promise<void> {
    await this.post(`/auth/customer/update`, customerRequest);
  }

  public async getOnlyOwnCustomer(): Promise<OnlyOwnCustomerSchema> {
    return this.get(`/auth/customer`);
  }

  public async getCustomerReleaseMailRecipients(customerId: string): Promise<SimpleUserRoleSchema[]> {
    return this.get(`/auth/customer/${customerId}/release-mail-recipients`);
  }

  //
  // QFly Server
  //

  public async getQFlyServers(): Promise<QFlyServerSchema[]> {
    return this.get(`/auth/qfly-servers`);
  }

  public async startQFlyServer(qflyServerUpdateRequest: QFlyServerUpdateRequest): Promise<void> {
    await this.post(`/auth/qfly-server/start`, qflyServerUpdateRequest);
  }

  public async stopQFlyServer(qflyServerUpdateRequest: QFlyServerUpdateRequest): Promise<void> {
    await this.post(`/auth/qfly-server/stop`, qflyServerUpdateRequest);
  }

  public async updateQFlyServer(qflyServerUpdateRequest: QFlyServerUpdateRequest): Promise<void> {
    await this.post(`/auth/qfly-server/update`, qflyServerUpdateRequest);
  }

  //
  // Runpod.io
  //

  public async getRunpodServers(): Promise<RunpodServerSchema[]> {
    return this.get(`/auth/runpod-servers`);
  }

  public async startRunpodServer(runpodServerId: string): Promise<void> {
    await this.post(`/auth/runpod-server/${runpodServerId}/start`);
  }

  public async stopRunpodServer(runpodServerId: string): Promise<void> {
    await this.post(`/auth/runpod-server/${runpodServerId}/stop`);
  }

  //
  // Observation - (Reference Measurements are Observations)
  //

  public async getReferenceMeasurements(analysisId: string): Promise<ReferenceMeasurementsSchema> {
    return await this.get(`/auth/reference-measurements/${analysisId}`);
  }

  public async moveObservations(sourceAnalysisId: string, targetAnalysisId: string): Promise<void> {
    await this.post(`/auth/observations/move`, 
      { source_analysis_id: sourceAnalysisId, target_analysis_id: targetAnalysisId });
  }

  public async ignoreReferenceMeasurement(observationId: string, ignore: boolean): Promise<void> {
    await this.post(`/auth/reference-measurement/${observationId}/ignore`, { ignore });
  }

  public async getSummerizedOberservations(
    plantId: string,
    summerizedObservationRequest: SummerizedObservationRequest
  ): Promise<SummerizedDates> {
    return await this.get(`/auth/plant/${plantId}/summerized-observations`, summerizedObservationRequest);
  }

  public async createObservation(
    plantId: string,
    observation: ObservationRequest,
    feature: FeatureLike
  ): Promise<CreatedObservationSchema> {
    return await this.post(`/auth/plant/${plantId}/observation`, observation);
  }

  public async addObservations(plantId: string, observations: ObservationsRequest): Promise<CreatedObservationSchema[]> {
    return await this.post(`/auth/plant/${plantId}/observations`, observations);
  }

  public async updateObservation(plantId: string, observationId: string, observation: ObservationRequest, feature: FeatureLike) {
    await this.post(`/auth/plant/${plantId}/observation/${observationId}`, observation);
  }

  public async deleteObservation(plantId: string, observationId: string, feature: FeatureLike): Promise<void> {
    await this.delete(`/auth/plant/${plantId}/observation/${observationId}`);
  }

  public async uploadObservationImages(
    plantId: string,
    observationId: string,
    images: File[],
    component: ApiComponent,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ) {
    const postImages: Record<string, File> = {};
    for (const file of images) {
      postImages[file.name] = file;
    }

    await this.postForm(`/auth/plant/${plantId}/observation/${observationId}/images`, postImages, onUploadProgress)
  }

  public async getObservationsGeoVisualCcp(
    plantId: string,
    ccpId: string,
    fromDate: string,
    toDate: string,
    filterValue: ObservFilterValue
  ): Promise<any> {
    return await this.get(`/auth/geo-visual/observations/${plantId}/ccp/${ccpId}/${fromDate}/${toDate}`, 
      filterValue !== undefined ? { filter_value: filterValue } : undefined);
  }

  public async getObservationsGeoVisualPi(
    plantId: string,
    piId: ApiPI,
    fromDate: string,
    toDate: string,
    filterValue: ObservFilterValue,
  ): Promise<any> {
    return await this.get(`/auth/geo-visual/observations/${plantId}/pi/${piId}/${fromDate}/${toDate}`,
      filterValue !== undefined ? { filter_value: filterValue } : undefined);
  }

  public async getObservations(
    plantId: string,
    componentId: number,
    dFrom: string,
    dTo: string,
    params: TableRequest,
    filterParams?: TableFilterRequest,
  ): Promise<TableResultSchema<ObservationSchema, ObservationColumn>> {
    return await this.get(`/auth/plant/${plantId}/observations/${componentId}/${dFrom}/${dTo}${
      this.getQueryParams(params)}${this.getEncodedAnalysisResultFilterParams(filterParams)}`);
  }

  public async getObservationsByDtIds(
    plantId: string,
    componentId: number,
    dFrom: string,
    dTo: string,
    params: TableRequest,
    observationFilter: ObservationFilterRequest,
    filterParams?: TableFilterRequest,
  ): Promise<TableResultSchema<ObservationSchema, ObservationColumn>> {
    return await this.post(`/auth/plant/${plantId}/observations/${componentId}/${dFrom}/${dTo}${
      this.getQueryParams(params)}${this.getEncodedAnalysisResultFilterParams(filterParams)}`,
      observationFilter
    );
  }

  public async createOrUpdateObservationForComponents(
    plantId: string,
    componentId: number,
    observationComponentsRequest: ObservationComponentsRequest,
  ): Promise<void> {
    await this.post(`/auth/plant/${plantId}/observation-for-components/${componentId}`, observationComponentsRequest);
  }

  public async deleteObservationForComponents(
    plantId: string,
    componentId: number,
    deleteObservationComponentsRequest: DeleteObservationComponentsRequest,
  ): Promise<void> {
    await this.post(`/auth/plant/${plantId}/delete-observation-for-components/${componentId}`, deleteObservationComponentsRequest);
  }

  //
  // Doc
  //

  public async getDocFiles(): Promise<DocFile[]> {
    return this.get(`/auth/doc/files`);
  }

  public async getDocFileUrl(fileId: string): Promise<{ url: string }> {
    return this.get(`/auth/doc/file/${fileId}`);
  }

  public async createDocFile(
    docFile: File,
    title?: string,
    description?: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<void> {
    const docFilePost = { doc_file: docFile };
    if (title) {
      docFilePost["title"] = title;
    }
    if (description) {
      docFilePost["description"] = description;
    }

    return this.postForm(`/auth/doc/file`, docFilePost, onUploadProgress);
  }

  public async editDocFile(
    fileId: string,
    docFile?: File,
    title?: string,
    description?: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<void> {
    const docFilePost = {};
    if (docFile) {
      docFilePost["doc_file"] = docFile;
    }
    if (title) {
      docFilePost["title"] = title;
    }
    if (description) {
      docFilePost["description"] = description;
    }

    if (Object.keys(docFilePost).length === 0) {
      return;
    }

    return this.postForm(`/auth/doc/file/${fileId}`, docFilePost, onUploadProgress);
  }

  public async deleteDocFile(fileId: string): Promise<void> {
    return this.delete(`/auth/doc/file/${fileId}`);
  }

  //
  // Drone
  //

  public async getDronePlantCoverage(analysisId: string): Promise<void> {
    return this.get(`/auth/analysis/${analysisId}/drone-plant-coverage`);
  }

  public async getDroneModels(): Promise<DroneModelSchema[]> {
    return this.get(`/auth/drone-models`);
  }

  public async getDroneModel(droneModelId: string): Promise<DroneModelSchema> {
    return this.get(`/auth/drone-model/${droneModelId}`);
  }

  public async createDroneModel(
    createDroneModelRequest: CreateDroneModelRequest,
  ): Promise<void> {
    return this.post(`/auth/create-drone-model`, createDroneModelRequest)
  }

  public async updateDroneModel(
    droneModelId: string,
    updateDroneModelRequest: UpdateDroneModelRequest,
  ): Promise<void> {
    return this.post(`/auth/drone-model/${droneModelId}`, updateDroneModelRequest)
  }

  public async deleteDroneModel(droneModelId: string): Promise<void> {
    await this.delete(`/auth/drone-model/${droneModelId}`);
  }

  public async getDrones(queryParams?: { customer_id?: string, plant_id?: string }): Promise<DroneSchema[]> {
    return this.get(`/auth/drones`, queryParams);
  }

  public async createDrone(
    createDroneRequest: CreateDroneRequest,
  ): Promise<void> {
    return this.post(`/auth/create-drone`, createDroneRequest)
  }

  public async updateDrone(
    droneId: string,
    updateDroneRequest: UpdateDroneRequest,
  ): Promise<void> {
    return this.post(`/auth/drone/${droneId}`, updateDroneRequest)
  }

  public async deleteDrone(droneId: string): Promise<void> {
    await this.delete(`/auth/drone/${droneId}`);
  }

  public async getAvailableDronesForAnalysis(analysisId: string): Promise<DroneSchema[]> {
    return this.get(`/auth/drones/${analysisId}`);
  }

  //
  // Flight Campaign and Flight Route
  //

  public async getFlightCampaignsOfAnalysis(analysisId: string): Promise<FlightCampaignSchema[]> {
    return this.get(`/auth/analysis/${analysisId}/flight-campaigns`);
  }

  public async getFlightRoutesOfFlightCampaign(flightCampaignId: string): Promise<FlightRouteSchema[]> {
    return this.get(`/auth/flight-campaign/${flightCampaignId}/flight-routes`);
  }

  public async getPlantActionsOfFlightCampaign(flightCampaignId: string): Promise<PlantOperationActionSchema[]> {
    return this.get(`/auth/flight-campaign/${flightCampaignId}/plant-actions`);
  }

  public getFlightRouteJsonUrl(flightRouteId: string): string {
    return `${apiBaseUrl}/auth/flight-route/${flightRouteId}/mission-json-file`;
  }

  public getFlightRouteDjiWpmlUrl(flightRouteId: string): string {
    return `${apiBaseUrl}/auth/flight-route/${flightRouteId}/dji-wpml-file`;
  }

  public getFlightRouteUnleashUrl(flightRouteId: string): string {
    return `${apiBaseUrl}/auth/flight-route/${flightRouteId}/unleash-json-file`;
  }

  public async exportFlightCampaignToUnleash(flightCampaignId: string): Promise<void> {
    return this.post(`/auth/flight-campaign/${flightCampaignId}/export-to-unleash`);
  }

  public async getFlighthub2Projects(): Promise<Flighthub2ProjectSchema[]> {
    return this.get(`/auth/fh2/projects`);
  }

  public async getFlighthub2FlightRoutesInProject(fh2_project_uuid: string): Promise<Flighthub2FlightRouteSchema[]> {
    return this.get(`/auth/fh2/${fh2_project_uuid}/flight-routes`);
  }

  public async getNumberOfDocksInFlighthub2Project(fh2_project_uuid: string): Promise<number> {
    const numDocksDict = await this.get(`/auth/fh2/${fh2_project_uuid}/number-of-docks-in-project`);
    return numDocksDict['numDocks'];
  }

  public async getUpcomingTasksInFlighthub2Project(fh2_project_uuid: string): Promise<Flighthub2TaskPlanSchema[]> {
    return this.get(`/auth/fh2/${fh2_project_uuid}/upcoming-tasks-in-project`);
  }

  public async exportFlightCampaignToFlighthub2(exportFlightCampaignFlighthub2Request: ExportFlightCampaignFlighthub2Request): Promise<void> {
    return this.post(`/auth/fh2/export-flight-campaign`, exportFlightCampaignFlighthub2Request);
  }

  public async deleteAllFlightRoutesInFlighthub2Project(fh2_project_uuid: string): Promise<void> {
    return this.delete(`/auth/fh2/${fh2_project_uuid}/delete-all-flight-routes-in-project`);
  }

  public async getMediaFoldersFlighthub2(fh2_project_uuid: string, parent_folder_id?: string): Promise<Flighthub2FolderSchema[]> {
    return this.get(`/auth/fh2/${fh2_project_uuid}/media-folders?parent_folder_id=${parent_folder_id ? parent_folder_id : ''}`);
  }

  public async importFilesFromFlighthub2(importFilesFromFlighthub2Request: ImportFilesFromFlighthub2Request): Promise<void> {
    return this.post(`/auth/fh2/download-media-files`, importFilesFromFlighthub2Request);
  }

  public async getMediaFoldersUnleash(): Promise<UnleashFolderSchema[]> {
    return this.get(`/auth/unleash-folders`);
  }

  public async importFilesFromUnleash(importFilesFromUnleashRequest: ImportFilesFromUnleashRequest): Promise<void> {
    return this.post(`/auth/download-unleash-files`, importFilesFromUnleashRequest);
  }

  public async createFlightCampaign(
    createFlightCampaignRequest: CreateFlightCampaignRequest,
  ): Promise<void> {
    return this.post(`/auth/flight-campaign`, createFlightCampaignRequest)
  }

  public async updateFlightCampaign(flightCampaignId: string, updateFlightCampaignRequest: UpdateFlightCampaignRequest): Promise<void> {
    await this.post(`/auth/flight-campaign/${flightCampaignId}/update`, updateFlightCampaignRequest);
  }

  public async deleteFlightCampaign(flightCampaignId: string): Promise<void> {
    await this.delete(`/auth/flight-campaign/${flightCampaignId}`);
  }

  public async getFlightRouteMissionJson(flightRouteId: string): Promise<FlightRouteMissionSchema> {
    return this.get(`/auth/flight-route/${flightRouteId}/mission`);
  }

  public async deleteFlightRoute(flightRouteId: string): Promise<void> {
    await this.delete(`/auth/flight-route/${flightRouteId}`);
  }

  public async deletePlantOperationAction(flightCampaignId: string, plantOperationActionId: string): Promise<void> {
    await this.delete(`/auth/flight-campaign/${flightCampaignId}/delete-plant-operation-action/${plantOperationActionId}`);
  }

  // 
  // Product Package and Order 
  //

  public async createProductPackage(
    createProductPackageRequest: CreateProductPackageRequest,
  ): Promise<void> {
    return this.post(`/auth/create-product-package`, createProductPackageRequest)
  }

  public async updateProductPackage(
    productPackageId: number,
    updateProductPackageRequest: UpdateProductPackageRequest,
  ): Promise<void> {
    return this.post(`/auth/product-package/${productPackageId}`, updateProductPackageRequest)
  }

  public async deleteProductPackage(productPackageId: number): Promise<void> {
    await this.delete(`/auth/product-package/${productPackageId}`);
  }

  public async getProductPackagesWithKeyFigures(): Promise<ProductPackageWithKeyFiguresSchema[]> {
    return this.get(`/auth/product-packages-with-key-figures`);
  }

  public async createOrder(
    createOrderRequest: CreateOrderRequest,
  ): Promise<void> {
    return this.post(`/auth/order`, createOrderRequest);
  }

  public async updateOrder(
    orderId: string,
    updateOrderRequest: UpdateOrderRequest,
  ): Promise<void> {
    return this.post(`/auth/order/${orderId}`, updateOrderRequest);
  }

  public async getOrders(plantId?: string | null, customerId?: string | null): Promise<OrderSchema[]> {
    const plantCustomerFilter = {};
    if (plantId) {
      plantCustomerFilter['plant_id'] = plantId;
    }
    if (customerId) {
      plantCustomerFilter['customer_id'] = customerId;
    }

    return this.get(`/auth/orders`, plantCustomerFilter);
  }

  public async deleteOrder(orderId: string): Promise<void> {
    return this.delete(`/auth/order/${orderId}`);
  }

  public async getOrderProductPackages(plantId: string, curDate?: string, customerId?: string): Promise<OrderProductPackageSchema[]> {
    const queryParams = {};
    if (curDate) {
      queryParams["cur_date"] = curDate;
    }
    if (customerId) {
      queryParams["customer_id"] = customerId;
    }

    return this.get(`/auth/order-product-packages/${plantId}`, queryParams);
  }

  public async getOrderPPsMulitselectOptions(plantId: string, curDate?: string, customerId?: string): Promise<MultiselectOption[]> {
    const orderPPs = await volateqApi.getOrderProductPackages(plantId, curDate, customerId);

    return orderPPs
      .map(orderPP => ({
        id: orderPP.id,
        label: orderPP.quantity ? 
          orderPP.product_package.name + " - Yearly " + orderPP.quantity : 
          orderPP.product_package.name
      }));
  }

  public async getNewAnalysisProductPackagesProposal(plantId: string, curDate?: string): Promise<OrderProductPackageSchema[]> {
    return this.get(`/auth/new-analysis-product-packages-proposal/${plantId}`, curDate ? { cur_date: curDate } : undefined)
  }

  public async getOrderPPDisabledKeyFigures(orderId: string): Promise<OrderPPKeyFiguresDisabledSchema[]> {
    return this.get(`/auth/order-product-packages/${orderId}/disabled-key-figures`);
  }

  //
  // Upload
  //

  public async getSecuredFilenames(filenames: string[]): Promise<SecuredFilename[]> {
    return this.post(`/auth/upload/secure-filenames`, { file_names: filenames });
  }

  public async createAnalysisUpload(createUploadRequest: CreateAnalysisUploadRequest): Promise<Upload> {
    return this.post(`/auth/upload/analysis`, createUploadRequest);
  }

  public async getUpload(uploadId: string): Promise<Upload> {
    return this.get(`/auth/upload/${uploadId}`);
  }

  public async uploadFileChunk(
    uploadId: string, 
    fileChunk: Blob,
    fileId: string,
    chunkNumber: number,
    onUploadProgressEvent?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<UploadChunkResult> {
    return this.postForm(`/auth/upload/${uploadId}`, { 
      file: fileChunk,
      file_id: fileId,
      chunk_number: (chunkNumber as unknown) as string
    }, onUploadProgressEvent)
  }

  public async resumeUpload(uploadId: string): Promise<void> {
    await this.post(`/auth/upload/${uploadId}/resume`);
  }

  public async cancelUpload(uploadId: string): Promise<void> {
    await this.post(`/auth/upload/${uploadId}/cancel`);
  }

  //
  // Report
  //

  public async createReport(plantId: string, plantImageFile: File, createReportRequest: CreateReportRequest): Promise<TaskSchema> {
    const formRecord: Record<string, string | File> = { "plant_image_file": plantImageFile };
    for (const k of Object.keys(createReportRequest)) {
      if (createReportRequest[k] !== null) {
        formRecord[k] = createReportRequest[k].toString();
      }
    }


    return await this.postForm(`/auth/plant/${plantId}/report`, formRecord);
  }

  public async getReports(plantId: string): Promise<ReportSchema[]> {
    return this.get(`/auth/plant/${plantId}/reports`);
  }

  public async getReportUrl(plantId: string, reportId: string): Promise<{ url: string }> {
    return this.get(`/auth/plant/${plantId}/report/${reportId}`);
  }

  public async deleteReport(plantId: string, reportId: string) {
    await this.delete(`/auth/plant/${plantId}/report/${reportId}`);
  }
}

export const volateqApi: VolateqAPI = new VolateqAPI();
