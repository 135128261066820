<template>
  <span class="app-spinner" :class="{ small }"></span>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { BaseComponent } from "../base-component/base-component";

@Component({
  name: "app-spinner",
})
export default class AppSpinner extends BaseComponent {
  @Prop({ default: false }) small!: boolean;


}
</script>
<style lang="scss">
@import "@/scss/_mixins.scss";

@include keyframes (spinner) {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.app-spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right: .25em solid transparent;
  border-radius: 50%;

  @include animate(spinner, .75s, linear, infinite);

  &.small {
    width: 1rem;
    height: 1rem;
    border-width: .2em;
  }
}

</style>