<template>
  <div>
    <strong>October 2024 (v8.12.0)</strong><br>
    <hr>
    <a href="#feature-1">Flight route visualization</a>
    <hr>
    <div>
      <h3 id="feature-1">Flight route visualization</h3>
      <p>
        In the "Flights" tab of an analysis, flight routes can now be selected and visualized. For each analysis, these are exactly the flight routes that will be flown by the VolaFly Android app. The flight routes are drawn onto a satellite view map, but the following demo images are from the south pole so the background is empty. The visualization works for all plant technologies and types of flight routes.
      </p>
      <img :src="flightRouteVisualization1" class="width-100pc mar-bottom" /><br>
      <p>
        The pop-up can be expanded for a more detailed view which also includes a table of the waypoints. Hover over waypoint action icons for a description. The altitude of each waypoint is shown directly above it in the map. A mobile-friendly styling allows for a visualization on your phone as well.
      </p>
      <img :src="flightRouteVisualization2" class="width-100pc mar-bottom" /><br>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseReleaseNotesComponent from "./base-release-notes-component"

@Component({
  name: "app-release-notes-v12",
})
export default class AppReleaseNotesV12 extends BaseReleaseNotesComponent {
  releaseNotesVersion = "v12"
  
  flightRouteVisualization1 = "";
  flightRouteVisualization2 = "";

  async mounted() {
    this.flightRouteVisualization1 = await this.getReleaseNoteImage(this.releaseNotesVersion, "flight_route_visualization_1.png");
    this.flightRouteVisualization2 = await this.getReleaseNoteImage(this.releaseNotesVersion, "flight_route_visualization_2.png");
  }
}
</script>
